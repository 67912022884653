import { useMemo } from 'react'
import {
  Availability,
  SERVICE_CATEGORIES,
  ServiceCategoryCode,
  type ScheduleFragment,
} from '@valerahealth/rtk-query'
import {
  Stack,
  IconButton,
  PopupMenu,
  ListItemText,
  ListItemButton,
  IconButtonLink,
  Divider,
  Paper,
  FormControl,
  InputLabel,
  Chip,
  Box,
  Tooltip,
  Checkbox,
  ListItemIcon,
  Menu,
  PopupState,
  MenuItem,
} from '@valerahealth/ui-components/base'
import {
  MoreHoriz,
  Add,
  DescriptionOutlined,
  Visibility,
  VisibilityOff,
} from '@valerahealth/ui-components/icons'
import { TFunction } from '@valerahealth/ui-translation'
import { useTranslation } from '../locales'
import { useCalendarContext, useProvider } from './ProviderCalendarContext'
import { useGetMutationPermissionsForPMCalendar } from '../hooks/useGetMutationPermissions'
import {
  actions,
  canceledStatus,
  useReduxDispatch,
  useReduxSelector,
} from '../reducer'
import { getDrChronoAppointmentURL } from '../utilities'
import { bindTrigger, bindMenu } from 'material-ui-popup-state'

type Props = {
  schedules?: ScheduleFragment[]
}

const AvailabilityStatusChip = ({
  value,
  t,
}: {
  value: Availability
  t: TFunction
}) => {
  let color: 'success' | 'warning' | 'error' = 'success'

  if (
    [
      Availability.CareCoordinatorOnly,
      Availability.WaitlistOnly,
      Availability.PilotOnly,
    ].includes(value)
  )
    color = 'warning'

  if (
    [
      Availability.Pending,
      Availability.Recruiting,
      Availability.Closed,
    ].includes(value)
  )
    color = 'error'

  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{
        minWidth: 100,
        position: 'relative',
      }}
    >
      <FormControl
        sx={{
          m: 1,
          minWidth: 100,
          position: 'absolute',
        }}
      >
        <InputLabel
          shrink
          sx={{
            position: 'absolute',
            backgroundColor: 'white',
            top: -9,
            left: -14,
            pl: 1,
            pr: 1,
          }}
        >
          {t('availability')}
        </InputLabel>
      </FormControl>
      <Chip
        sx={{
          m: 1,
        }}
        size="small"
        label={t(`Availability.${value}`)}
        color={color}
      />
    </Paper>
  )
}

export const AvailabilityStatusWithNote = () => {
  const {
    provider: { availability, availabilityNotes },
  } = useCalendarContext()
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        minWidth: 300,
        display: 'flex',
        justifyItems: 'center',
      }}
    >
      {availability && <AvailabilityStatusChip value={availability} t={t} />}
      {availabilityNotes && (
        <Tooltip title={availabilityNotes} placement="right-end">
          <IconButton sx={{ ml: 0, pl: 0 }} disableRipple>
            <DescriptionOutlined />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}

export default function ActionButtons({ schedules }: Props) {
  const provider = useProvider()
  const dispatch = useReduxDispatch()
  const { filterCanceledAppts } = useReduxSelector((state) => state.scheduling)
  const { t } = useTranslation()
  const {
    canCreateSchedule,
    canReadAppointment,
    canCreateAppointment,
    canReadSchedule,
  } = useGetMutationPermissionsForPMCalendar(provider._id)
  const scheduleTypes = useMemo(
    () =>
      schedules
        ? SERVICE_CATEGORIES.filter((c) =>
            schedules.some((v) => v.serviceCategory.code === c.code),
          )
        : [],
    [schedules],
  )

  return (
    <Stack direction="row" justifyContent="flex-end" gap={2}>
      <IconButton
        title={
          filterCanceledAppts
            ? 'Show Canceled Appointments'
            : 'Hide Canceled Appointments'
        }
        sx={{
          color: (theme) => theme.palette.secondary.main,
          borderRadius: '64px',
        }}
        disableRipple
        onClick={() => {
          dispatch(actions.setfilterCanceledAppts(!filterCanceledAppts))
        }}
      >
        {filterCanceledAppts ? <VisibilityOff /> : <Visibility />}
      </IconButton>

      {process.env.SCHEDULING_ADD_EDIT_APPOINTMENTS ? (
        canCreateAppointment && (
          <IconButton
            sx={{
              backgroundColor: (theme) => theme.palette.divider,
              borderRadius: '64px',
            }}
            onClick={() => {
              dispatch(
                actions.openView({
                  type: 'appointmentForm',
                  mode: 'add',
                  code: ServiceCategoryCode.Patient,
                  initialState: {
                    providerId: provider._id,
                  },
                }),
              )
            }}
          >
            <Add />
          </IconButton>
        )
      ) : (
        <IconButtonLink
          to={getDrChronoAppointmentURL()}
          sx={{
            backgroundColor: (theme) => theme.palette.divider,
            borderRadius: '64px',
          }}
        >
          <Add />
        </IconButtonLink>
      )}
      {((scheduleTypes.length && canReadSchedule) ||
        canCreateSchedule ||
        canReadAppointment) && (
        <PopupMenu
          variant="menu"
          button={
            <IconButton
              sx={{
                backgroundColor: (theme) => theme.palette.divider,
                borderRadius: '64px',
              }}
              disableRipple
            >
              <MoreHoriz />
            </IconButton>
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {canCreateSchedule && (
            <ListItemButton
              onClick={() => {
                dispatch(
                  actions.openView({
                    type: 'scheduleForm',
                    mode: 'add',
                    provider,
                  }),
                )
              }}
            >
              <ListItemText
                sx={{
                  mr: 'auto',
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {t('Add Schedule')}
              </ListItemText>
              <Add sx={{ color: (theme) => theme.palette.action.active }} />
            </ListItemButton>
          )}
          {scheduleTypes.map(({ code }) => (
            <ListItemButton
              key={code}
              onClick={() =>
                dispatch(
                  actions.openView({
                    type: 'scheduleList',
                    code,
                    provider,
                  }),
                )
              }
            >
              <ListItemText>{t(`ServiceCategoryCode.${code}`)}</ListItemText>
            </ListItemButton>
          ))}
          <Divider />
          <ListItemButton
            onClick={() =>
              dispatch(
                actions.openView({
                  type: 'appointmentList',
                  code: ServiceCategoryCode.OutOfOffice,
                  mode: 'future',
                  providerId: provider._id,
                }),
              )
            }
          >
            <ListItemText>
              Upcoming{' '}
              {t(`ServiceCategoryCode.${ServiceCategoryCode.OutOfOffice}`)}
            </ListItemText>
          </ListItemButton>
        </PopupMenu>
      )}
    </Stack>
  )
}
