/* eslint react/no-array-index-key: 0 */
import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { format } from 'date-fns'
import { SxProps } from '../../base'
import { Theme } from '../../theme'

interface HoverFullContentCellProps {
  value?: number | string | JSX.Element | JSX.Element[] | null
  popperValue?: number | string | JSX.Element | JSX.Element[] | null
  width: number
}

export interface ContentCellSection {
  header?: string
  values?: string
  contentStyle?: SxProps<Theme>
}

function isOverflown(element: Element): boolean {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  )
}

export const HoverFullContentCell = React.memo(function HoverFullContentCell(
  props: HoverFullContentCellProps,
) {
  const { width } = props
  const value = props.value ?? ''
  const popperValue = props.popperValue ?? ''
  const wrapper = React.useRef<HTMLDivElement | null>(null)
  const cellDiv = React.useRef(null)
  const cellValue = React.useRef(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [showFullCell, setShowFullCell] = React.useState(false)
  const [showPopper, setShowPopper] = React.useState(false)

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!)
    setShowPopper(isCurrentlyOverflown)
    setAnchorEl(cellDiv.current)
    setShowFullCell(true)
  }

  const handleMouseLeave = () => {
    setShowFullCell(false)
  }

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [setShowFullCell, showFullCell])

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 1,
        height: 1,
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '1px',
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          sx={{ maxWidth: '20rem', zIndex: (theme) => theme.zIndex.tooltip }}
        >
          <Paper
            elevation={1}
            sx={{
              padding: (theme) => theme.spacing(1),
              overflowWrap: 'anywhere',
            }}
          >
            <Typography component="div" variant="body2">
              {popperValue || value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  )
})

export function renderHoverFullContentCell(
  params: GridRenderCellParams<any, string | string[] | null | undefined>,
) {
  return (
    <HoverFullContentCell
      value={
        Array.isArray(params.formattedValue)
          ? params.formattedValue.join(', ')
          : params.formattedValue || ''
      }
      width={params.colDef.computedWidth}
    />
  )
}

export function renderHoverSectionedContentCell(
  params: GridRenderCellParams<
    any,
    Array<ContentCellSection> | null | undefined
  >,
) {
  return (
    <HoverFullContentCell
      value={params.formattedValue
        ?.map((section) =>
          section.header
            ? `${section.header}: ${section.values}`
            : section.values,
        )
        .join(', ')}
      popperValue={params.formattedValue?.map((section) => (
        <Box sx={section.contentStyle}>
          <Typography
            component="div"
            variant="body2"
            sx={{ fontWeight: 'bold' }}
          >
            {section.header}
          </Typography>
          <Typography component="div" variant="body2">
            {section.values}
          </Typography>
        </Box>
      ))}
      width={params.colDef.computedWidth}
    />
  )
}

export function renderHoverFullContentCellNewLine(
  params: GridRenderCellParams<
    any,
    | string
    | number
    | Array<string | number | null | undefined>
    | null
    | undefined
  >,
) {
  return (
    <HoverFullContentCell
      value={
        Array.isArray(params.value)
          ? params.value.map((val, i) => <Box key={i}>{val}&nbsp;</Box>)
          : params.value || ''
      }
      width={params.colDef.computedWidth}
    />
  )
}

export function renderHoverFullContentCellDateNewLine(
  params: GridRenderCellParams<
    any,
    Array<Date | null | undefined> | null | undefined
  >,
) {
  return (
    <HoverFullContentCell
      value={
        params.value?.map((val, i) => {
          const display = val ? format(val, 'MM/dd/yyyy') : ''
          return <div key={i}>{display}&nbsp;</div>
        }) || ''
      }
      width={params.colDef.computedWidth}
    />
  )
}

export function renderHoverFullContentCellStringNewLine(
  params: GridRenderCellParams<
    any,
    Array<String | null | undefined> | null | undefined
  >,
) {
  return (
    <HoverFullContentCell
      value={
        params.value?.map((val, i) => {
          return <div key={i}>{val}&nbsp;</div>
        }) || ''
      }
      width={params.colDef.computedWidth}
    />
  )
}
