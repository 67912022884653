import { combineReducers, type Reducer } from 'redux'
import reduceReducers from 'reduce-reducers'
import { persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/es/storage/session'
import { authReducer } from '@valerahealth/redux-auth'
import {
  practiceMgrApi,
  taskApi,
  careManagerApi,
  patientRegistrationApi,
  userAdminApi,
  noteApi,
  patientSearchApi,
  referenceDataApi,
  configApi,
  schedulingApi,
  auditApi,
  appointmentIntegrationsApi,
} from '@valerahealth/rtk-query'
import storage from 'redux-persist/lib/storage'
import { notificationsReducer } from '@valerahealth/ui-components/features'
import { persistentDataGridReducer } from '@valerahealth/ui-components/grid'
import {
  schedulingReducer,
  schedulingReducerPath,
} from '@valerahealth/scheduling'
import caseload from './caseloadReducer'
import treatmentRoom from './treatmentRoomReducer'
import planTemplates from './planTemplateReducer'
import toolsTemplates from './toolsTemplatesReducer'
import planReducer from './planReducer'
import global from './globalReducer'
import program from './programReducer'
import supervision from './supervisionReducer'
import provider from './providerReducer'

import { type LegacyRootState } from './legacyRootState'

const persistRootConfig = {
  key: 'root',
  version: 2,
  storage,
  whitelist: [],
}

const persistedSchedulingReducer = persistReducer(
  {
    key: schedulingReducerPath,
    storage: sessionStorage,
  },
  schedulingReducer,
)

const combinedReducer = combineReducers({
  global: global as Reducer<LegacyRootState['global']>,
  supervision: supervision as Reducer<LegacyRootState['supervision']>,
  caseload: caseload as Reducer<LegacyRootState['caseload']>,
  treatmentRoom: treatmentRoom as Reducer<LegacyRootState['treatmentRoom']>,
  program: program as Reducer<LegacyRootState['program']>,
  planTemplates: planTemplates as Reducer<LegacyRootState['planTemplates']>,
  toolsTemplates: toolsTemplates as Reducer<LegacyRootState['toolsTemplates']>,
  provider: provider as Reducer<LegacyRootState['provider']>,
  ...persistentDataGridReducer({
    version: 2,
  }),
  [schedulingReducerPath]: persistedSchedulingReducer,
  // apiCalls,
  [noteApi.reducerPath]: noteApi.reducer,
  [practiceMgrApi.reducerPath]: practiceMgrApi.reducer,
  [patientRegistrationApi.reducerPath]: patientRegistrationApi.reducer,
  ...authReducer,
  ...notificationsReducer,
  [userAdminApi.reducerPath]: userAdminApi.reducer,
  [taskApi.reducerPath]: taskApi.reducer,
  [careManagerApi.reducerPath]: careManagerApi.reducer,
  [patientSearchApi.reducerPath]: patientSearchApi.reducer,
  [referenceDataApi.reducerPath]: referenceDataApi.reducer,
  [configApi.reducerPath]: configApi.reducer,
  [schedulingApi.reducerPath]: schedulingApi.reducer,
  [auditApi.reducerPath]: auditApi.reducer,
  [appointmentIntegrationsApi.reducerPath]: appointmentIntegrationsApi.reducer,
})
export type RootReducer = typeof combinedReducer

const rootReducer = persistReducer(
  persistRootConfig,
  reduceReducers(combinedReducer, planReducer),
) as RootReducer

export type RootState = ReturnType<RootReducer>

export default rootReducer
