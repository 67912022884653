import { useEffect, useMemo } from 'react'
import {
  AllInsurancePlansMinimalFragment,
  BillingFullFragment,
  GQLServerErrorResponse,
  patientRegistrationApi,
  patientSearchApi,
  PatientSummaryFrag,
  practiceMgrApi,
  PriorAuthStatus,
  ProviderFragment,
} from '@valerahealth/rtk-query'
import { providerToFullName, useNotify } from '@valerahealth/ui-components'
import {
  isValidDateAndNonNull,
  isBefore,
  isAfter,
} from '@valerahealth/ui-components/utils/date'

const emptyIns: AllInsurancePlansMinimalFragment[] = []

type PatientApptMetaQueriesReturnType = {
  provider: ProviderFragment | null
  patientBilling: BillingFullFragment | null
  patientSummary: PatientSummaryFrag | undefined
  insurancePlans: AllInsurancePlansMinimalFragment[]
  isProviderLoading: boolean
  isPatientBillingLoading: boolean
  isInsurancePlansLoading: boolean
  isAnyLoading: boolean
}

export type UsePatientApptMetaQueriesArg = {
  providerId: string | null | undefined
  treatmentId: string | null | undefined
}
export const usePatientApptMetaQueries = ({
  providerId,
  treatmentId,
}: UsePatientApptMetaQueriesArg) => {
  const notify = useNotify()
  const {
    provider,
    isLoading: isProviderLoading,
    error: getProviderError,
  } = practiceMgrApi.useGetProviderQuery(
    {
      id: providerId!,
    },
    {
      skip: !providerId,
      selectFromResult: ({ isFetching, error, currentData }) => ({
        error,
        isLoading: isFetching,
        provider: currentData?.getProvider ?? null,
      }),
    },
  )
  useEffect(() => {
    if (getProviderError) {
      notify({
        severity: 'warning',
        message:
          getProviderError.message ||
          "An error occured while loading the rendering provider's information",
      })
    }
  }, [getProviderError, notify])

  const {
    patientBilling,
    isLoading: isPatientBillingLoading,
    error: getBillingRecordError,
  } = patientRegistrationApi.useLoadPatientBillingQuery(
    {
      input: { treatmentId: treatmentId! },
    },
    {
      skip: !treatmentId,
      selectFromResult: ({ isFetching, error, currentData }) => {
        return {
          isLoading: isFetching,
          error: error as GQLServerErrorResponse,
          patientBilling: currentData?.loadPatientBilling ?? null,
        }
      },
    },
  )
  useEffect(() => {
    // some patients may not have billing record which is okay
    if (
      getBillingRecordError &&
      getBillingRecordError.name !== 'NotFoundException' &&
      getBillingRecordError.message !== 'patient_billing_not_found'
    ) {
      notify({
        severity: 'warning',
        message:
          getBillingRecordError.message ||
          "An error occured while loading the patient's billing information",
      })
    }
  }, [getBillingRecordError, notify])

  const {
    data: insurancePlans,
    isLoading: isInsurancePlansLoading,
    error: getInsuranceError,
  } = practiceMgrApi.useGetAllInsuranceMinimalQuery(undefined, {
    selectFromResult: ({ data = emptyIns, isLoading, error }) => ({
      data,
      isLoading,
      error,
    }),
  })
  useEffect(() => {
    if (getInsuranceError) {
      notify({
        severity: 'warning',
        message:
          getInsuranceError.message ||
          'An error occured while loading insurance information',
      })
    }
  }, [getInsuranceError, notify])

  const {
    data: patientSummary,
    isLoading: isDescribePatientLoading,
    error: describePatientError,
  } = patientSearchApi.useDescribePatientsByTreatmentIdQuery(
    { treatmentIds: treatmentId ? [treatmentId] : [] },
    {
      skip: !treatmentId,
      selectFromResult: ({ data, isLoading, error }) => ({
        data: data?.describePatientsByTreatmentId?.[0],
        isLoading,
        error,
      }),
    },
  )
  useEffect(() => {
    if (describePatientError) {
      notify({
        severity: 'warning',
        message:
          describePatientError.message ||
          'An error occured while loading patient information',
      })
    }
  }, [describePatientError, notify])

  const apptMeta: PatientApptMetaQueriesReturnType = useMemo(
    () => ({
      provider,
      patientBilling,
      patientSummary,
      insurancePlans,
      isProviderLoading,
      isInsurancePlansLoading,
      isPatientBillingLoading,
      isAnyLoading:
        isProviderLoading || isInsurancePlansLoading || isPatientBillingLoading || isDescribePatientLoading,
    }),
    [
      provider,
      patientBilling,
      insurancePlans,
      isProviderLoading,
      isInsurancePlansLoading,
      isPatientBillingLoading,
      isDescribePatientLoading,
      patientSummary,
    ],
  )
  return apptMeta
}

export type PatientAppointmentMetaArg = {
  appointmentDate: Date | null
} & UsePatientApptMetaQueriesArg

export const usePatientApptMeta = ({
  appointmentDate,
  treatmentId,
  providerId,
}: PatientAppointmentMetaArg) => {
  const { provider, patientBilling, insurancePlans, ...rest } =
    usePatientApptMetaQueries({
      treatmentId,
      providerId,
    })

  const info = useMemo(() => {
    const { fullConsentPackedSigned, creditCardOnFile, selfPay, insurances } =
      patientBilling || {}

    const patientInsurance = selfPay
      ? null
      : Object.fromEntries(
          (insurances || [])
            .filter((v) => v.type && (v.outOfNetwork || v.insurancePlanId))
            .map(
              ({
                type,
                deductible,
                familyDeductible,
                eligibilityVerified,
                coPay,
                coinsurance,
                outOfNetworkPlan,
                outOfNetwork,
                insurancePlanId,
                priorAuthRequired,
              }) => {
                const { name, stateCode = '' } = (insurancePlanId
                  ? insurancePlans.find((v) => v._id === insurancePlanId)
                  : {
                      name: outOfNetworkPlan?.name || '',
                      stateCode: outOfNetworkPlan?.state?.code || '',
                    }) || { name: '', stateCode: '' }
                const planName = `${name}${stateCode && ` - ${stateCode}`}`

                const providerPlan =
                  appointmentDate &&
                  provider?.insurancePlans?.find((_i) => {
                    return (
                      _i.insurancePlan._id === insurancePlanId &&
                      (!isValidDateAndNonNull(_i.effectiveDate) ||
                        isAfter(
                          appointmentDate,
                          new Date(_i.effectiveDate!),
                        )) &&
                      (!isValidDateAndNonNull(_i.terminationDate) ||
                        isBefore(
                          appointmentDate,
                          new Date(_i.terminationDate!),
                        ))
                    )
                  })

                const billingProvider = provider && {
                  isActive: appointmentDate ? !!providerPlan : null,
                  //fallback to provider themself as the billing provider
                  name:
                    providerPlan?.billingRelationship?.reference?.display ||
                    provider?.display?.legalQualifiedName ||
                    providerToFullName(provider),
                }

                return [
                  type!,
                  {
                    billingProvider,
                    planName,
                    outOfNetwork,
                    eligibilityVerified,
                    coPay,
                    coinsurance,
                    deductible,
                    familyDeductible,
                    isHighDeductible:
                      typeof deductible === 'number'
                        ? deductible >= 1600
                        : false,
                    priorAuthRequired:
                      priorAuthRequired === PriorAuthStatus.Required,
                  },
                ]
              },
            ),
        )

    return {
      selfPay,
      fullConsentPackedSigned,
      creditCardOnFile,
      providerSupervisor: provider?.supervisor?.display,
      patientInsurance,
    }
  }, [provider, patientBilling, insurancePlans, appointmentDate])

  return {
    ...info,
    provider,
    insurancePlans,
    patientBilling,
    ...rest,
  }
}

export type PatientAppointmentMetaReturnType = ReturnType<
  typeof usePatientApptMeta
>
export type PatientAppointmentMetaPatientInsurance = NonNullable<
  PatientAppointmentMetaReturnType['patientInsurance']
>[string]
