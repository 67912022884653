import { BaseApiType } from './baseApi'

export default function enhanceEndpoints(api: BaseApiType) {
  return api.enhanceEndpoints({
    endpoints: {
      checkEmrAvailability: {
        keepUnusedDataFor: 0,
        providesTags: (res, err, req) => {
          return [
            { type: 'availability' as const, id: req!.content?.providerEmrId },
          ]
        },
      },
    },
  })
}
