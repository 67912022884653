import { useMemo } from 'react'
import { SlotInfo } from '@valerahealth/ui-components/features/Calendar'
import {
  SearchFilterEnum,
  schedulingApi,
  Operator,
  AppointmentParticipantTypeCode,
  ServiceCategoryCode,
} from '@valerahealth/rtk-query'
import { Typography } from '@valerahealth/ui-components/base'
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  setHours,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  fromZonedTime,
  toZonedTime,
} from '@valerahealth/ui-components/utils/date'
import { Calendar, ActionButtons } from '../components'
import ProviderCalendarContextProvider, {
  type ProviderCalendarContextProps,
} from '../components/ProviderCalendarContext'
import { useGetPermissions } from '../hooks'
import {
  actions,
  canceledStatus,
  nonCanceledStatus,
  useReduxDispatch,
  useReduxSelector,
} from '../reducer'
import { AvailabilityStatusWithNote } from '../components/ActionButtons'

export type ProviderScheduleProps = Omit<
  ProviderCalendarContextProps,
  'children'
>

const nonCanceledStatusSet = new Set(nonCanceledStatus)

const { useGetSchedulesByProviderIdQuery, useSearchAppointmentsQuery } =
  schedulingApi

export default function ProviderCalendar({ provider }: ProviderScheduleProps) {
  const dispatch = useReduxDispatch()

  const {
    calendarState: { calenderDate, view, timezone },
    filterCanceledAppts,
  } = useReduxSelector((state) => state.scheduling)

  const { canReadAppointment, canReadSchedule, canCreateAppointment } =
    useGetPermissions(provider._id, 'providerId')

  const timeWindow: { start: Date; end: Date } | null = useMemo(() => {
    const dt = toZonedTime(calenderDate, timezone)
    switch (view) {
      case 'day':
        return {
          start: startOfDay(dt),
          end: endOfDay(dt),
        }
      case 'week':
        return {
          start: startOfWeek(dt),
          end: endOfWeek(dt),
        }
      case 'work_week':
        return {
          start: startOfWeek(dt, { weekStartsOn: 1 }),
          end: subDays(endOfWeek(dt), 1),
        }
      case 'month':
        return {
          start: startOfMonth(dt),
          end: endOfMonth(dt),
        }
      default:
        return null
    }
  }, [calenderDate, view, timezone])

  const { schedules, ...scheduleStatus } = useGetSchedulesByProviderIdQuery(
    {
      id: provider._id,
    },
    {
      skip: !canReadSchedule,
      selectFromResult: ({ data, isFetching, isError }) => ({
        isFetching,
        isError,
        schedules: data?.searchSchedules?.results,
      }),
    },
  )
  const { appointments: _appointments, ...appointmentStatus } =
    useSearchAppointmentsQuery(
      {
        content: [
          {
            filter: SearchFilterEnum.ProviderId,
            value: provider._id,
          },
          {
            filter: SearchFilterEnum.ParticipantTypeCode,
            value: AppointmentParticipantTypeCode.PrimaryPerformer,
          },
          {
            filter: SearchFilterEnum.EndDate,
            operator: Operator.Gte,
            value: timeWindow?.start.toISOString() || '',
          },
          {
            filter: SearchFilterEnum.StartDate,
            operator: Operator.Lte,
            value: timeWindow?.end.toISOString() || '',
          },
        ],
        pageSize: 10000,
      },
      {
        skip: !timeWindow || !canReadAppointment,
        selectFromResult: ({ isFetching, isError, data }) => ({
          isFetching,
          isError,
          appointments: data?.searchAppointments?.results,
        }),
      },
    )
  const appointments = useMemo(() => {
    if (!_appointments) return undefined
    const filteredAppts = filterCanceledAppts
      ? _appointments.filter((a) => nonCanceledStatusSet.has(a.status))
      : _appointments
    return [
      ...filteredAppts.filter((a) => nonCanceledStatusSet.has(a.status)),
      ...filteredAppts.filter((a) => !nonCanceledStatusSet.has(a.status)),
    ]
  }, [_appointments, filterCanceledAppts])

  if (scheduleStatus.isError || appointmentStatus.isError) {
    return <Typography color="error">Failed to load data</Typography>
  }

  const openAppointmentForm = (slotInfo: SlotInfo) => {
    const startDateTime = fromZonedTime(
      view === 'month' ? setHours(slotInfo.start, 8) : slotInfo.start,
      timezone,
    ).toISOString()

    dispatch(
      actions.openView({
        type: 'appointmentForm',
        mode: 'add',
        code: ServiceCategoryCode.Patient,
        initialState: {
          providerId: provider._id,
          startDateTime,
          timezone,
        },
      }),
    )
  }

  return (
    <ProviderCalendarContextProvider provider={provider}>
      <Calendar
        isLoading={appointmentStatus.isFetching || scheduleStatus.isFetching}
        actionButtons={<ActionButtons schedules={schedules} />}
        otherComponents={<AvailabilityStatusWithNote />}
        schedules={schedules}
        appointments={appointments}
        {...(process.env.SCHEDULING_ADD_EDIT_APPOINTMENTS &&
        canCreateAppointment
          ? {
              selectable: true,
              onSelectSlot: openAppointmentForm,
            }
          : null)}
      />
    </ProviderCalendarContextProvider>
  )
}
