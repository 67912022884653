import { configureStore, AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import {
  practiceMgrApi,
  taskApi,
  careManagerApi,
  patientRegistrationApi,
  noteApi,
  userAdminApi,
  patientSearchApi,
  referenceDataApi,
  configApi,
  schedulingApi,
  auditApi,
  appointmentIntegrationsApi,
} from '@valerahealth/rtk-query'
import reducer, { RootState } from './reducers/index'
import {
  maintainSettingsMiddleware,
  applyLegacyStateUpdates,
} from './middleware'

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      noteApi.middleware,
      userAdminApi.middleware,
      practiceMgrApi.middleware,
      patientRegistrationApi.middleware,
      taskApi.middleware,
      careManagerApi.middleware,
      patientSearchApi.middleware,
      referenceDataApi.middleware,
      configApi.middleware,
      schedulingApi.middleware,
      auditApi.middleware,
      appointmentIntegrationsApi.middleware,
      maintainSettingsMiddleware,
      applyLegacyStateUpdates,
    ]),
})

const persistor = persistStore(store)

type DispatchType = ThunkDispatch<any, undefined, AnyAction>
const useReduxDispatch = () => useDispatch() as DispatchType

const useReduxSelector: TypedUseSelectorHook<RootState> = useSelector

type GetStateType = typeof store.getState
export {
  type RootState,
  type GetStateType,
  type DispatchType,
  persistor,
  useReduxDispatch,
  useReduxSelector,
}
export default store
