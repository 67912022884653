import {
    GRID_DETAIL_PANEL_TOGGLE_FIELD,
    GridColumnVisibilityModel,
  } from '../../grid'
  
  export enum PatientSearchColumnKey {
    id = 'id',
    mrn = 'mrn',
    name = 'name',
    status = 'status',
    dischargeReason = 'dischargeReason',
    dateOfBirth = 'dateOfBirth',
    primaryPhone = 'primaryPhone',
    primaryEmail = 'primaryEmail',
    timeZone = 'timeZone',
    nextAppointment = 'nextAppointment',
    upcomingAppointments = 'upcomingAppointments',
    genderIdentity = 'genderIdentity',
    careTeam = 'careTeam',
    npctm = 'npctm',
    warnings = 'warnings',
    tags = 'tags',
    actions = 'actions',
    insurance = 'insurance',
    messages = 'messages',
    globalTracks = 'globalTracks',
    individualTracks = 'individualTracks',
  }

  export const defaultVisibleColumns = [
    PatientSearchColumnKey.name,
    PatientSearchColumnKey.mrn,
    PatientSearchColumnKey.careTeam,
    PatientSearchColumnKey.dateOfBirth,
    PatientSearchColumnKey.genderIdentity,
    PatientSearchColumnKey.primaryEmail,
    PatientSearchColumnKey.primaryPhone,
    PatientSearchColumnKey.status,
    PatientSearchColumnKey.actions,
    PatientSearchColumnKey.nextAppointment,
    PatientSearchColumnKey.messages,
  ]
  
  export const defaultColumnVisibilityModel: GridColumnVisibilityModel = {
    ...Object.fromEntries(
      Object.values(PatientSearchColumnKey).map((v) => [
        v,
        defaultVisibleColumns.includes(v),
      ]),
    ),
    [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
  }
  