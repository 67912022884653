import { useMemo, useEffect, memo } from 'react'
import { createSelector } from '@reduxjs/toolkit'
import {
  SessionType,
  ServiceTypeCode,
  practiceMgrApi,
  ActiveStatus,
  LocationFragment,
  STATES_HASH,
  AppointmentFragment,
  NoticeCode,
  schedulingApi,
  AppointmentStatus,
  SearchFilterEnum,
  Operator,
  configApi,
  NUMBER_TO_DAY,
  DayOfWeek,
  ConflictErrorResponse,
  ProviderFragment,
  ProviderServiceType,
  ProviderIdentifierType,
} from '@valerahealth/rtk-query'
import T_LABELS from '@valerahealth/ui-translation/locales/en'
import {
  Comment,
  Grid,
  MenuItem,
  PatientTypeAhead,
  Stack,
  Typography,
  useNotify,
  useConfirmationDialog,
  TreatmentSendConsentPacketLink,
  ListItemText,
  Tooltip,
  Box,
  providerToFullName,
  Button,
  WorkflowSidebarContent,
  WorkflowSidebarActions,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@valerahealth/ui-components'
import {
  Combobox,
  DatePicker,
  ProviderSelect,
  SaveButton,
  Select,
  TextField,
  defaultProviderSelectSortFn,
  useFormContext,
} from '@valerahealth/ui-components/form'
import {
  TIMEZONE_VALUES,
  addMinutes,
  addDays,
  dateTimeTupleToDate,
  endOfWeek,
  formatInTimeZone,
  getTimezoneValues,
  strToDate,
  isSameDay,
  startOfWeek,
  subYears,
  isValidDateOrNull,
  isFuture,
  fromZonedTime,
  getUSTimezone,
} from '@valerahealth/ui-components/utils/date'
import { checkPermission, Permission } from '@valerahealth/redux-auth'
import {
  EXCLUDE_APPT_STATUS_FOR_WARNING,
  SESSION_TYPES,
  PATIENT_APPOINTMENT_SERVICE_TYPES,
  EDIT_APPOINTMENT_STATUSES,
  THERAPY_SERVICE_TYPES,
  GROUP_THERAPY_SERVICE_TYPES,
  PSYCHIATRY_SERVICE_TYPES,
  BILLABLE_SERVICE_TYPES,
  isAppointmentStatusDisabled,
  CANCELED_APPOINTMENT_STATUSES_SET,
  PROVIDER_SERVICE_TYPES_TO_SERVICE_TYPE_CODE,
  CancelIcon,
} from '../../utilities'
import { useTranslation } from '../../locales'
import { FormType, SubmittedFormType, formToAppointment } from './formType'
import { actions, useReduxDispatch, useReduxSelector } from '../../reducer'
import {
  RecurringAppointmentFormPart,
  TimeSelectionFormPart,
} from './FormParts'
import {
  handleAppointmentConflicts,
  handleAppointmentWarningConflicts,
  useFormPatientApptMeta,
} from './patientApptHelpers'
import EditAppointmentSeriesDialog from './EditAppointmentSeries'
import { AppointmentAlerts as BaseAppointmentAlerts } from '../PatientAppointmentMeta/AppointmentAlerts'
import { ContinuedAvailabilityMsg } from './ContinuedAvailabilityMsg'
import { NextGenAvailabilityMsg } from './NextGenAvailabilityMsg'

const AppointmentAlerts = memo(() => {
  const args = useFormPatientApptMeta()
  return <BaseAppointmentAlerts {...args} sx={{ my: 1 }} />
})

const { useGetListQuery } = configApi
const timezoneOptions = getTimezoneValues()

const ServiceTypeOptions = PATIENT_APPOINTMENT_SERVICE_TYPES.map(
  (value: ServiceTypeCode) => ({
    value,
    label: T_LABELS.ServiceTypeCode[value],
  }),
).sort((a, b) => a.label.localeCompare(b.label))

type AppointmentFormProps = {
  appointment?: AppointmentFragment | null
  locations: LocationFragment[]
  activePracticeStates: Set<string>
}

type FormWarningMessageProps = {
  message: string
}

const selectProviderLicenseStates = createSelector(
  (provider: ProviderFragment | null | undefined) => provider?.licenses,
  (licenses) =>
    new Set(
      licenses
        ?.filter((v) => v.status === ActiveStatus.Active)
        ?.map((v) => v.stateCode) || [],
    ),
)

const FormWarningMessage = ({ message }: FormWarningMessageProps) => (
  <Stack direction="row" alignItems="center" sx={{mt: '8px'}}>
    <CancelIcon
      sx={{
        color: (theme) => theme.palette.error.main,
        fontSize: (theme) => theme.typography.caption,
        lineHeight: '1.2',
        ml: '10px',
      }}
    />
    <Typography
      sx={{
        color: (theme) => theme.palette.error.main,
        fontSize: (theme) => theme.typography.caption,
        lineHeight: '1.2',
        ml: '5px',
      }}
    >
      {message}
    </Typography>
  </Stack>
)

const AppointmentForm = ({
  appointment,
  locations,
  activePracticeStates,
}: AppointmentFormProps) => {
  const canCreateAny = useReduxSelector((state) =>
    checkPermission(state, Permission.Appointment_Create),
  )
  const [t] = useTranslation()
  const notify = useNotify()
  const dispatch = useReduxDispatch()
  const { confirm, ConfirmationDialog } = useConfirmationDialog()

  const methods = useFormContext<FormType>()
  const { watch, setValue, getValues } = methods

  const timezone = watch('timezone')
  const serviceType = watch('serviceType')
  const apptDate = watch('startDate')
  const startTime = watch('startTime')
  const minutesDuration = watch('minutesDuration')
  const channelType = watch('channelType')
  const location = watch('location')
  const patient = watch('patient')
  const status = watch('status')
  const reoccuranceInfo = watch('reoccuranceInfo')
  const providerId = watch('providerId')

  const { fullConsentPackedSigned, patientStateCode, patientNextGenId } =
    useMemo(
      () => ({
        fullConsentPackedSigned: !patient?.notices?.some(
          (n) => n.code === NoticeCode.MissingConsent,
        ),
        patientStateCode: patient?.address?.state,
        patientNextGenId: patient?.nextgenId,
      }),
      [patient],
    )

  const locationStateCode = location?.address?.state

  const { data, isLoading: isLoadingConfig } = useGetListQuery({
    keys: ['apptCancelationReasons'],
  })
  const cancelationReasons = data?.getList[0]?.list || []

  const {
    providerServiceTypes,
    providerLicenseStates,
    providerNextGenId,
    providerNextGenResourceId,
    isSuccess: providerLicenseStatesHasLoaded,
    isFetching: isLoadingProviderLicenseStates,
  } = practiceMgrApi.useGetProviderQuery(
    {
      id: providerId!,
    },
    {
      skip: !providerId,
      selectFromResult: ({ currentData, isFetching, isSuccess }) => ({
        providerServiceTypes: currentData?.getProvider.serviceTypes || [],
        providerLicenseStates: selectProviderLicenseStates(
          currentData?.getProvider,
        ),
        providerNextGenId:
          currentData?.getProvider.identifiers?.find(
            (x) => x.type === ProviderIdentifierType.NextgenId,
          )?.value || null,
        providerNextGenResourceId:
          currentData?.getProvider.identifiers?.find(
            (x) => x.type === ProviderIdentifierType.NextgenResourceId,
          )?.value || null,
        isSuccess,
        isFetching,
      }),
    },
  )

  const careTeam: { [careManagerId: string]: { isPrimary: boolean } } =
    useMemo(() => {
      return Object.fromEntries(
        patient?.careTeam?.map((v) => [v.careManagerId, v]) || [],
      )
    }, [patient?.careTeam])

  const showAddressURL =
    channelType &&
    [SessionType.GoogleMeet, SessionType.Other].includes(channelType)

  // this is in the browsers timezone
  const startDateTime = useMemo(
    () => apptDate && startTime && dateTimeTupleToDate(apptDate, startTime),
    [apptDate, startTime],
  )

  // tz adjusted
  const startDateTimeIso = useMemo(
    () =>
      (startDateTime && timezone && fromZonedTime(startDateTime, timezone)) ||
      null,
    [startDateTime, timezone],
  )
  const [endDateTimeIso, appIsInFuture] = useMemo(() => {
    const dt =
      (startDateTimeIso &&
        minutesDuration &&
        addMinutes(startDateTimeIso, minutesDuration)) ||
      null

    return [dt, !!dt && isFuture(dt)] as const
  }, [startDateTimeIso, minutesDuration])

  const patientTimeZone = getUSTimezone(patient?.timeZone)

  const patientTzWarning = useMemo(() => {
    if (
      !startDateTimeIso ||
      !endDateTimeIso ||
      !patient?.timeZone ||
      patientTimeZone
    )
      return null

    const timeZoneLabel = TIMEZONE_VALUES.find(
      (v) => v.value === patientTimeZone,
    )?.label

    return `Patient in ${timeZoneLabel}, ${formatInTimeZone(
      startDateTimeIso,
      patientTimeZone,
      'h:mmaaa',
    )} - ${formatInTimeZone(endDateTimeIso, patientTimeZone, 'h:mmaaa')}`
  }, [startDateTimeIso, endDateTimeIso, patient?.timeZone, patientTimeZone])

  // Effect to try to preselelct best location
  useEffect(() => {
    if (
      providerLicenseStatesHasLoaded &&
      (!location || !providerLicenseStates.has(location.address.state))
    ) {
      const bestMatch =
        locations.find(
          (l) =>
            activePracticeStates.has(l.address.state) &&
            providerLicenseStates.has(l.address.state) &&
            l.physicalType.code === 'telehealth',
        ) ?? null
      setValue('location', bestMatch)
    }
  }, [
    activePracticeStates,
    providerLicenseStates,
    providerLicenseStatesHasLoaded,
    location,
    locations,
    setValue,
  ])

  const sortedLocations = useMemo(() => {
    return locations.concat().sort((a, b) => {
      const aActive = activePracticeStates.has(a.address.state)
      const bActive = activePracticeStates.has(b.address.state)
      return aActive && bActive ? 0 : aActive ? -1 : 1
    })
  }, [locations, activePracticeStates])

  //Effect to clear reoccurance if its not allowed
  useEffect(() => {
    // can occur if we  set fields for an appointment in the future, fill out reoccurance info and then change the appointment to be in the past
    if (reoccuranceInfo && !appIsInFuture && !appointment) {
      setValue('reoccuranceInfo', null)
    }
  }, [appIsInFuture, appointment, reoccuranceInfo, setValue])

  const [createAppointment, createAppointmentStatus] =
    schedulingApi.useCreateAppointmentV2Mutation()

  const [updateAppointment, updateAppointmentStatus] =
    schedulingApi.useUpdateAppointmentMutation()

  const { isError, isSuccess } =
    createAppointmentStatus || updateAppointmentStatus

  const fetchAppointmentsByDateRange = async (start: Date, end: Date) => {
    const { data, error } = await dispatch(
      schedulingApi.endpoints.searchAppointments.initiate({
        content: [
          {
            filter: SearchFilterEnum.PatientId,
            value: patient?.treatmentId!,
          },
          {
            filter: SearchFilterEnum.StartDate,
            operator: Operator.Gte,
            value: start.toISOString(),
          },
          {
            filter: SearchFilterEnum.StartDate,
            operator: Operator.Lte,
            value: end.toISOString(),
          },
        ],
      }),
    )

    if (error) {
      notify({
        message: error.message || 'Failed to retrieve appointments for patient',
        severity: 'error',
      })

      return
    }

    return data ? data.searchAppointments?.results : undefined
  }

  const getAppointmentWarnings = async (
    serviceType: ServiceTypeCode,
    _startDate: Date,
    status: AppointmentStatus,
  ) => {
    const warnings: string[] = []

    if (
      BILLABLE_SERVICE_TYPES.includes(serviceType) &&
      !EXCLUDE_APPT_STATUS_FOR_WARNING.includes(status)
    ) {
      const [startDate, endDate] = [
        startOfWeek(_startDate, {
          weekStartsOn: 0,
        }), // previous sunday
        endOfWeek(_startDate, {
          weekStartsOn: 0,
        }), // next saturday
      ]
      const appts: Array<AppointmentFragment> | undefined = (
        await fetchAppointmentsByDateRange(startDate, endDate)
      )?.filter(
        (appt) =>
          !EXCLUDE_APPT_STATUS_FOR_WARNING.includes(appt.status) &&
          appt._id !== appointment?._id,
      )

      if (appts) {
        if (
          appts.find(
            ({ startDate: apptStartDate, serviceType }) =>
              BILLABLE_SERVICE_TYPES.includes(serviceType?.code!) &&
              isSameDay(_startDate, strToDate(apptStartDate)!),
          )
        ) {
          warnings.push(t('billableApptWarning'))
        }

        if (
          [...THERAPY_SERVICE_TYPES, ...GROUP_THERAPY_SERVICE_TYPES].includes(
            serviceType,
          )
        ) {
          if (
            appts.find(({ serviceType }) =>
              [
                ...THERAPY_SERVICE_TYPES,
                ...GROUP_THERAPY_SERVICE_TYPES,
              ].includes(serviceType?.code!),
            )
          ) {
            warnings.push(t('therapyApptWarning'))
          }
        }

        if (PSYCHIATRY_SERVICE_TYPES.includes(serviceType)) {
          if (
            appts.find(({ serviceType }) =>
              PSYCHIATRY_SERVICE_TYPES.includes(serviceType?.code!),
            )
          ) {
            warnings.push(t('psychiatryApptWarning'))
          }
        }
      }
    }

    return warnings
  }

  const serviceTypeError = useMemo(() => {
    if (!serviceType || !providerServiceTypes.length) return null

    const allowedServiceTypes = providerServiceTypes.reduce(
      (
        acc: Array<ServiceTypeCode>,
        providerServiceType: ProviderServiceType,
      ) => {
        acc.push(
          ...PROVIDER_SERVICE_TYPES_TO_SERVICE_TYPE_CODE[providerServiceType],
        )
        return acc
      },
      [],
    )

    return !allowedServiceTypes.includes(serviceType!)
      ? t(`serviceTypeCodeWarning`, {
          selectedServiceType: T_LABELS.ServiceTypeCode[serviceType],
          allowedServiceTypes: providerServiceTypes
            .map((_type) => T_LABELS.ProviderServiceType[_type])
            .join(', '),
        })
      : null
  }, [providerServiceTypes, serviceType, t])

  const onFormSubmit = async (
    fields: SubmittedFormType,
    forceOverlap = false,
  ) => {
    const appointmentId = appointment?._id
    const { reoccuranceTemplate, ...content } = formToAppointment(fields)

    // forceOverlap = true means this is the second save attempt and the user has already seen warnings and confirmed
    if (!forceOverlap) {
      const warnings = await getAppointmentWarnings(
        content.serviceType,
        new Date(content.startDate),
        content.status,
      )

      if (warnings.length) {
        const confirmed = await confirm({
          header: t('confirmScheduleApptHeader'),
          body: warnings.map((w) => (
            <Typography key={w} gutterBottom>
              {w}
            </Typography>
          )),
          confirmLabel: t('schedule'),
          cancelLabel: t('cancel'),
        })
        if (!confirmed) return
      }

      if (!fullConsentPackedSigned && patient?.primaryEmail) {
        const confirmed = await confirm({
          header: t('consentPacketWarning'),
          body: (
            <>
              <Typography>{t('consentPacketWarningMessage')}</Typography>
              <TreatmentSendConsentPacketLink
                email={patient.primaryEmail}
                treatmentId={patient.treatmentId}
              />
            </>
          ),
          confirmLabel: t('yes'),
          cancelLabel: t('no'),
        })
        if (!confirmed) return
      }
    }

    const res = appointmentId
      ? await updateAppointment({
          id: appointmentId,
          content,
          options: { forceOverlap },
        })
      : await createAppointment({
          content: {
            ...content,
            reoccuranceTemplate,
          },
          options: { forceOverlap },
        })

    // force overlap workflow before throwing api error

    if ('error' in res) {
      if ('isConflict' in res.error) {
        const allowOverlap = await handleAppointmentConflicts(
          res.error as ConflictErrorResponse,
          fields,
          content.status!,
          confirm,
        )
        if (allowOverlap)
          await onFormSubmit({ ...fields, status: allowOverlap }, true)
        return
      }
      notify({
        message: res.error.message || t('api.appointment.saveFailure'),
        severity: 'error',
      })
      return false
    }

    if (
      'createAppointmentV2' in res.data &&
      res.data.createAppointmentV2.warnings
    ) {
      const { warnings } = res.data.createAppointmentV2
      await handleAppointmentWarningConflicts(warnings, timezone, confirm)
    }

    notify({
      message: t(`api.appointment.saveSuccess`),
      severity: 'success',
    })

    dispatch(actions.closeView())
  }

  const onFormValidationFail = () => {
    notify({
      message: t('form_invalid'),
      severity: 'warning',
    })
  }
  return (
    <>
      <Stack
        component="form"
        sx={{ height: '100%' }}
        onSubmit={methods.handleSubmit(
          (values) => onFormSubmit(values as SubmittedFormType),
          onFormValidationFail,
        )}
      >
        <WorkflowSidebarContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            wrap: 'nowrap',
          }}
        >
          <Grid container spacing={2} sx={{ flexGrow: 1 }} alignContent="start">
            <Grid item xs={12}>
              <PatientTypeAhead
                label={t('Patient')}
                name="patient"
                required
                sx={{
                  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    borderColor: (theme) =>
                      !fullConsentPackedSigned || (patient && !patientNextGenId)
                        ? theme.palette.error.main
                        : '',
                  },
                }}
                textFieldProps={{
                  color:
                    !fullConsentPackedSigned || (patient && !patientNextGenId)
                      ? 'error'
                      : undefined,
                }}
                readOnly={!!appointment}
              />

              {!fullConsentPackedSigned && (
                <FormWarningMessage message={t('consentPacketNotSigned')} />
              )}

              {patient && !patientNextGenId && (
                <FormWarningMessage message={t('missingNextGenIdPatient')} />
              )}
            </Grid>

            {
              /*only show if editing an existing appointment*/ appointment?._id &&
                startDateTime && (
                  <Grid item xs={12}>
                    <Combobox
                      label={t('appointmentStatus')}
                      name="status"
                      options={EDIT_APPOINTMENT_STATUSES}
                      getOptionDisabled={(status) =>
                        isAppointmentStatusDisabled(startDateTime, status)
                      }
                      fullWidth
                      getOptionLabel={(v) =>
                        v ? t(`AppointmentStatus.${v}`) : ''
                      }
                      required
                    />
                  </Grid>
                )
            }

            {
              /*cancel appointment*/ appointment?._id &&
                startDateTime &&
                CANCELED_APPOINTMENT_STATUSES_SET.has(status!) && (
                  <>
                    <Grid item xs={12}>
                      <Combobox
                        disabled={isLoadingConfig}
                        label={t('cancelationReason')}
                        name="cancelationReason.reason"
                        options={cancelationReasons}
                        fullWidth
                        getOptionLabel={(v) => v.label}
                        getOptionKey={(v) => v.value}
                        setValueAs={(v) => v?.value || ''}
                        parseValue={(value: ServiceTypeCode | null, options) =>
                          options.find((v) => v.value === value) || null
                        }
                        required={CANCELED_APPOINTMENT_STATUSES_SET.has(
                          status!,
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="cancelationReason.detail"
                        label={t('cancelationDetail')}
                        multiline
                        fullWidth
                      />
                    </Grid>
                  </>
                )
            }

            <Grid item xs={12}>
              <Combobox
                label={t('service')}
                name="serviceType"
                options={ServiceTypeOptions}
                fullWidth
                setValueAs={(v) => v?.value || null}
                parseValue={(value: ServiceTypeCode | null, options) =>
                  options.find((v) => v.value === value) || null
                }
                getOptionLabel={(v) => (v ? v.label : '')}
                required
                helperText={serviceTypeError}
                textFieldProps={{
                  error: !!serviceTypeError,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ProviderSelect
                fullWidth
                name="providerId"
                label={t('provider')}
                setValueAs={(p) => (p ? p._id : null)}
                parseValue={(value: string, options) =>
                  options.find((o) => o._id === value) || null
                }
                required
                sx={{
                  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    borderColor: (theme) =>
                      providerId &&
                      (!providerNextGenResourceId || !providerNextGenId)
                        ? theme.palette.error.main
                        : '',
                  },
                }}
                textFieldProps={{
                  color:
                    providerId &&
                    (!providerNextGenResourceId || !providerNextGenId)
                      ? 'error'
                      : undefined,
                }}
                sortOptions={(a, b) => {
                  const aCtm = careTeam[a.careManagerId || '-1']
                  const bCtm = careTeam[b.careManagerId || '-1']
                  if (aCtm?.isPrimary) return -1
                  if (bCtm?.isPrimary) return 1
                  if (aCtm && !bCtm) return -1
                  if (bCtm && !aCtm) return 1
                  return defaultProviderSelectSortFn(a, b)
                }}
                renderOption={(spread, option, { selected }) => {
                  const ctm = careTeam[option.careManagerId || '-1']
                  return (
                    <MenuItem {...spread} key={option._id} selected={selected}>
                      <ListItemText
                        primary={
                          option.display?.expandedName ||
                          providerToFullName(option)
                        }
                        secondary={
                          ctm &&
                          (ctm.isPrimary
                            ? 'Primary Care Team Member'
                            : 'Care Team Member')
                        }
                      />
                    </MenuItem>
                  )
                }}
                getOptionLabel={(provider) =>
                  (provider.display && provider.display.qualifiedName) ||
                  providerToFullName(provider)
                }
                readOnly={!!appointment || !canCreateAny}
              />

              {providerId &&
                (!providerNextGenResourceId || !providerNextGenId) && (
                  <FormWarningMessage message={t('missingNextGenIdProvider')} />
                )}
            </Grid>
            <Grid item xs={12}>
              <Combobox
                name="location"
                label={t('location')}
                required
                fullWidth
                getOptionLabel={(o) => o.name}
                isOptionEqualToValue={(o, v) => o.id === v.id}
                options={sortedLocations}
                loading={isLoadingProviderLicenseStates}
                renderOption={(spread, option, { selected }) => {
                  const stateCode = option.address.state
                  const disabled =
                    !providerLicenseStates.has(stateCode) ||
                    !activePracticeStates.has(stateCode)
                  return (
                    <Tooltip
                      key={option.id}
                      placement="left"
                      title={
                        !providerLicenseStates.has(stateCode)
                          ? `The Provider is not licensed in ${stateCode}`
                          : !activePracticeStates.has(stateCode)
                          ? 'Valera does not currently operate in this state'
                          : undefined
                      }
                    >
                      <Box>
                        <MenuItem
                          title={`The Provider is not licensed in ${stateCode}`}
                          {...spread}
                          key={option.id}
                          selected={selected}
                          disabled={disabled}
                        >
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      </Box>
                    </Tooltip>
                  )
                }}
              />

              {locationStateCode &&
                patientStateCode &&
                locationStateCode !== patientStateCode && (
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.warning.main,
                      fontSize: (theme) => theme.typography.caption,
                      ml: '2px',
                      mt: '2px',
                      lineHeight: '1.2',
                    }}
                  >
                    {t('patientIn')} {STATES_HASH[patientStateCode]}
                  </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
              <Combobox
                label={t('sessionType')}
                name="channelType"
                options={SESSION_TYPES}
                getOptionLabel={(o) => t(`SessionType.${o}`)}
                fullWidth
                required
                onChange={() => {
                  setValue('addressUrl', '', {
                    shouldDirty: true,
                  })
                }}
              />
            </Grid>
            {showAddressURL && (
              <Grid item xs={12}>
                <TextField
                  name="addressUrl"
                  label={
                    channelType !== SessionType.Other ? t('url') : t('other')
                  }
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <DatePicker
                label={t('date')}
                name="startDate"
                format="iiii, MMMM d, yyyy"
                fullWidth
                maxDate={addDays(new Date(), 90)}
                minDate={subYears(new Date(), 2)}
                required
                onChange={(event: { target: { value: Date | null } }) => {
                  const newValue = event.target.value
                  // when editing a series (on create appointment... NOT edit) adjust the day of week with the selected date
                  if (!appointment && isValidDateOrNull(newValue)) {
                    const daysOfWeek = getValues('reoccuranceInfo.dayOfWeek')
                    if (daysOfWeek) {
                      // remove the old and add the new
                      const dow = new Set(daysOfWeek)
                      const previous =
                        apptDate && NUMBER_TO_DAY[apptDate.getDay()]
                      const next = newValue && NUMBER_TO_DAY[newValue.getDay()]
                      if (previous) dow.delete(previous)
                      if (next) dow.add(next)
                      setValue('reoccuranceInfo.dayOfWeek', [...dow])
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select label={t('timezone')} name="timezone" fullWidth required>
                {timezoneOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>

              {patientTzWarning && (
                <Typography
                  sx={{
                    color: (theme) => theme.palette.warning.main,
                    fontSize: (theme) => theme.typography.caption,
                    ml: '2px',
                    mt: '2px',
                    lineHeight: '1.2',
                  }}
                >
                  {patientTzWarning}
                </Typography>
              )}
            </Grid>

            <TimeSelectionFormPart serviceType={serviceType} />

            <Grid item xs={12}>
              <ContinuedAvailabilityMsg startDateTimeIso={startDateTimeIso} />
            </Grid>

            {startDateTimeIso &&
              endDateTimeIso &&
              providerNextGenResourceId && (
                <Grid item xs={12}>
                  <NextGenAvailabilityMsg
                    startDate={startDateTimeIso!}
                    endDate={endDateTimeIso!}
                    providerEmrId={providerNextGenResourceId}
                    patientEmrId={patient?.nextgenId || undefined}
                  />
                </Grid>
              )}

            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <FormControlLabel
                  label="Repeat"
                  control={
                    <Checkbox
                      // disabled if apptDate is not filled in or if we are editing an existing appointment
                      disabled={!appIsInFuture || !!appointment}
                      checked={!!reoccuranceInfo}
                      onChange={(e, checked) => {
                        if (checked) {
                          // default it to the day of the week of the appointment itself
                          const dayOfWeek = [
                            NUMBER_TO_DAY[apptDate?.getDay() ?? -1] ||
                              DayOfWeek.Monday,
                          ]
                          setValue('reoccuranceInfo', {
                            dayOfWeek,
                            interval: 1,
                            endDate: null,
                          })
                        } else {
                          setValue('reoccuranceInfo', null)
                        }
                      }}
                    />
                  }
                />
                {appointment?.reoccuranceTemplate &&
                  appointment?.serviceType?.code && (
                    <EditAppointmentSeriesDialog
                      serviceType={appointment.serviceType.code}
                      reoccuranceTemplate={appointment.reoccuranceTemplate}
                    />
                  )}
              </Stack>
              {appointment?.occuranceChanged && (
                <FormHelperText
                  sx={{ color: (theme) => theme.palette.info.main }}
                >
                  This appointment occurance was edited. Edits to the series
                  will not effect this appointment.
                </FormHelperText>
              )}
            </Grid>

            {reoccuranceInfo && (
              <Grid item xs={12}>
                <RecurringAppointmentFormPart
                  readOnly={!!appointment}
                  // checkbox is disabled if apptDate is null
                  startDate={apptDate!}
                  requireStartDateDayOfWeek={!appointment}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                name="description"
                label={t('description')}
                multiline
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="comment"
                label={t('comment')}
                multiline
                fullWidth
              />
            </Grid>
            {!!appointment?.notes?.length && (
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  {t('comments')}
                </Typography>
                {appointment?.notes && (
                  <Stack gap={1}>
                    {appointment.notes.map(
                      ({ text, time, authorReference: { display } }) => (
                        <Comment
                          key={time}
                          author={display}
                          text={text}
                          date={new Date(time)}
                        />
                      ),
                    )}
                  </Stack>
                )}
              </Grid>
            )}
          </Grid>
          {appIsInFuture &&
            !!patient &&
            (!status || status === AppointmentStatus.Booked) && (
              <AppointmentAlerts />
            )}
        </WorkflowSidebarContent>
        <WorkflowSidebarActions>
          <Button
            variant="text"
            onClick={() => {
              dispatch(actions.closeView())
            }}
          >
            Cancel
          </Button>
          <SaveButton isError={isError} isSuccess={isSuccess} label="Save" />
        </WorkflowSidebarActions>
      </Stack>
      <ConfirmationDialog />
    </>
  )
}

export default AppointmentForm
