import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  genderIdentityArray,
  patientSearchApi,
  type SearchablePatientFrag,
  PatientStatus,
  configApi,
  InsuranceOrdinality,
  practiceMgrApi,
  AllInsurancePlansMinimalFragment,
  careManagerApi,
  CMChannelItemOverview,
  isWellnessSelector,
  Track,
  CMTracks,
} from '@valerahealth/rtk-query'
import {
  Permission,
  RootStateWithAuth,
  checkPermission,
} from '@valerahealth/redux-auth'
import T_LABELS from '@valerahealth/ui-translation/locales/en'
import {
  AggregationsAggregationContainer,
  SearchSortContainer,
} from '@opensearch-project/opensearch/api/types'
import { formatIsoDate, getTimezoneValues } from '../../utils/date'
import {
  GridFilterItem,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  HoverFullContentCell,
  PersistentDataGrid,
  renderHoverFullContentCell,
  renderHoverFullContentCellNewLine,
  ExpandCollapseCell,
  useServerSideGrid,
  OperatorValueSingleSelect,
  ServerSideColumn,
  GridSortItem,
  handleSelectOsFilter,
  GridInitialState,
  usePersistentGridInitialState,
  QueryDslQueryContainer,
  defaultQuickFilterSortOverride,
  PersistentDataGridProps,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridColDef,
  getOsGridSingleSelectOperators,
  OperatorValueStr,
  GridColumnVisibilityModel,
  handleNestedOsType,
  OsColumnType,
  GridFilterItemToOSQuery,
} from '../../grid'
import {
  Box,
  IconButton,
  Link,
  Typography,
  Button,
  Stack,
  Tooltip,
  CircularProgress,
} from '../../base'
import { useFeatureFlagNoStartSession } from '../../utils/hooks/useFeatureFlagNoStartSession'
import { StartPatientVideoSession } from '../StartPatientVideoSession'
import { Add, MarkEmailRead, NotInterested, Star, VideoCall } from '../../icons'
import { DrChronoIcon, NextGenIcon } from '../Icons'
import { useNotify } from '../Notifications'
import { useTranslation } from '../../utils'
import {
  PatientSearchColumnKey as ColumnKey,
  defaultColumnVisibilityModel,
} from './columns'
import {
  PatientSearchGridRowModel,
  WarningChip,
  WarningFilterOptions,
  formatAppt,
  handleCareTeamFilter,
  handleUpcomingAppointmentsFilter,
  nextAppointmentFilterOperators,
  patientToRow,
  quickFilterFields,
  useCareTeamAggregates,
} from './utils'
import { useTreatmentActionsMenu } from '../TreatmentActions'
import { RegisterTreatmentButton } from '../RegisterTreatment/RegisterTreatmentModal'

const GRID_NAME = `${process.env.ENV}_PATIENTS`

const emptyRes: SearchablePatientFrag[] = []

const hiddenFields = [GRID_DETAIL_PANEL_TOGGLE_FIELD]

const getTogglableColumns = (columns: GridColDef[]) => {
  return columns
    .filter((column) => !hiddenFields.includes(column.field))
    .map((column) => column.field)
}

const defaultGridState: GridInitialState = {
  columns: {
    columnVisibilityModel: defaultColumnVisibilityModel,
  },
  sorting: {
    sortModel: [{ field: ColumnKey.name, sort: 'asc' }],
  },
  filter: {
    filterModel: {
      items: [
        {
          field: ColumnKey.status,
          operator: OperatorValueSingleSelect.is,
          value: {
            value: PatientStatus.Active,
            label: T_LABELS.PatientStatus[PatientStatus.Active],
          },
        },
      ],
    },
  },
}

const { useSearchPatientsQuery } = patientSearchApi
const { useGetListQuery } = configApi

export type TagsAggsResultType = {
  tags: {
    buckets: { key: string; doc_count: number }[]
  }
  tracks: {
    tracks: { buckets: { key: string; doc_count: number }[] }
  }
}

const defaultTagsAggregationInput: Record<
  string,
  AggregationsAggregationContainer
> = {
  tags: {
    terms: {
      field: 'tags.keyword',
      size: 2000,
    },
  },
  tracks: {
    nested: {
      path: 'tracks',
    },
    aggs: {
      tracks: {
        terms: {
          size: 2000,
          script:
            "doc['tracks.name.keyword'].value + '|' + doc['tracks.type.keyword'].value",
        },
      },
    },
  },
}
type TrackType = 'population' | 'individual_public'

const filterItemToOSParamForTracks =
  (trackType: TrackType): GridFilterItemToOSQuery =>
  (item, field) => {
    switch (item.operator) {
      case OperatorValueSingleSelect.isAnyOf:
        if (
          !item.value ||
          (Array.isArray(item.value) && item.value.length === 0)
        ) {
          return undefined
        }
        return {
          bool: {
            filter: {
              nested: {
                path: 'tracks',
                query: {
                  bool: {
                    must: [{ term: { 'tracks.type.keyword': trackType } }],
                    minimum_should_match: 1,
                    should: (item.value as string[]).map(
                      (v) =>
                        ({
                          term: { 'tracks.name.keyword': v },
                        } as QueryDslQueryContainer),
                    ),
                  },
                },
              },
            },
          },
        }
      case OperatorValueSingleSelect.isEmpty:
        return {
          bool: {
            must_not: {
              bool: {
                filter: {
                  nested: {
                    path: 'tracks',
                    query: {
                      bool: {
                        must: [{ term: { 'tracks.type.keyword': trackType } }],
                      },
                    },
                  },
                },
              },
            },
          },
        }
      case OperatorValueSingleSelect.isNotEmpty:
        return {
          bool: {
            filter: {
              nested: {
                path: 'tracks',
                query: {
                  bool: {
                    must: [{ term: { 'tracks.type.keyword': trackType } }],
                  },
                },
              },
            },
          },
        }
      default:
        return undefined
    }
  }

interface MessagesDetailPanelProps {
  treatmentId: string
  idsWithTreatmentNotificationMap: Map<string, CMChannelItemOverview[]>
}
function MessagesDetailPanel({
  treatmentId,
  idsWithTreatmentNotificationMap,
}: MessagesDetailPanelProps) {
  const [open, setOpen] = useState<boolean>(false)
  const notify = useNotify()
  const [setChannelSeen, { isLoading: isLoadingSettingChannel }] =
    careManagerApi.useSetChannelSeenMutation()
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        backgroundColor: (theme) => theme.palette.grey[100],
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
      }}
    >
      {open ? (
        <Box
          sx={{
            width: '100%',
          }}
        >
          {idsWithTreatmentNotificationMap.get(treatmentId)!.map((n) => (
            <Typography
              key={n.ts}
              sx={{
                color: (theme) => theme.palette.grey[600],
                fontSize: (theme) => theme.typography.body2,
                p: '4px 10px 10px 10px',
                display: 'inline-block',
                width: 'calc(100%)',
                cursor: 'pointer',
              }}
              onClick={() => setOpen(false)}
            >
              {n.text}
            </Typography>
          ))}
        </Box>
      ) : (
        <Box
          onClick={() => setOpen(true)}
          sx={{
            height: '36px',
            width: '100%',
            overflow: 'hidden',
            cursor: 'pointer',
          }}
        >
          <Typography
            sx={{
              color: (theme) => theme.palette.grey[600],
              fontSize: (theme) => theme.typography.body2,
              p: '4px 10px 10px 10px',
              display: 'inline-block',
              width: 'calc(100%)',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {idsWithTreatmentNotificationMap.get(treatmentId)![0]?.text}
          </Typography>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          width: '120px',
          ml: 'auto',
          alignItems: 'center',
          cursor: isLoadingSettingChannel ? undefined : 'pointer',
        }}
        onClick={async () => {
          if (isLoadingSettingChannel) {
            return
          }
          const res = await setChannelSeen({
            treatmentId,
          })
          if ('error' in res) {
            console.error(res.error)
            notify({
              severity: 'error',
              message: 'An error occurred while marking message as read.',
            })
          }
          notify({
            severity: 'success',
            message: 'Successfully marked messages as read.',
          })
        }}
      >
        {isLoadingSettingChannel ? (
          <CircularProgress size="1rem" />
        ) : (
          <MarkEmailRead />
        )}
        <Typography sx={{ fontSize: (theme) => theme.typography.caption }}>
          Mark as Read
        </Typography>
      </Box>
    </Box>
  )
}

export interface PatientSearchGridProps {
  formatPatientLink: (id: string) => string
  idsWithTreatmentNotificationArray?: string[]
  idsWithTreatmentNotificationMap?: Map<string, CMChannelItemOverview[]>
}

export function PatientSearchGrid({
  formatPatientLink,
  idsWithTreatmentNotificationArray,
  idsWithTreatmentNotificationMap,
}: PatientSearchGridProps) {
  const [t] = useTranslation()
  const isWellness = useSelector(isWellnessSelector)
  const canAddPatient = useSelector((state: RootStateWithAuth) =>
    checkPermission(state, Permission.Patient_Create),
  )

  const notify = useNotify()

  const {
    tracks,
    tags,
    error: aggsError,
  } = patientSearchApi.useGetAggregatesQuery(defaultTagsAggregationInput, {
    selectFromResult: ({ data, error }) => ({
      tags: (data as TagsAggsResultType | undefined)?.tags,
      tracks: (data as TagsAggsResultType | undefined)?.tracks,
      error,
    }),
  })

  const [globalTracks, individualPublicTracks] = useMemo(() => {
    const parsedTracks = tracks?.tracks?.buckets?.map((t) => t.key.split('|'))

    const g: string[] =
      parsedTracks
        ?.filter(([name, type]) => type === 'population' && name)
        .map(([name]) => name as string) || []
    const i: string[] =
      parsedTracks
        ?.filter(([name, type]) => type === 'individual_public' && name)
        .map(([name]) => name as string) || []

    return [g.sort(), i.sort()]
  }, [tracks])

  const {
    data: _insurancePlans,
    error: insurancePlansError,
    isLoading: isInsuranceLoading,
  } = practiceMgrApi.useGetAllInsuranceMinimalQuery(undefined, {
    skip: !isWellness,
    selectFromResult: ({ isLoading, error, data }) => ({
      isLoading,
      error,
      data,
    }),
  })

  const { TreatmentActionsMenuPopups, TreatmentActionsMenu } =
    useTreatmentActionsMenu()
  const hideStartSessionButton = useFeatureFlagNoStartSession()
  const initialState = usePersistentGridInitialState(
    GRID_NAME,
    defaultGridState,
  )

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(
      initialState?.columns?.columnVisibilityModel ||
        defaultColumnVisibilityModel,
    )

  const detailPanelExpandedRowIds =
    columnVisibilityModel[ColumnKey.messages] === false
      ? []
      : idsWithTreatmentNotificationArray

  const handleColumnVisibilityModelChange = (
    newModel: GridColumnVisibilityModel,
  ) => {
    setColumnVisibilityModel(newModel)
  }

  const { dischargeReasonList, error: dischargeReasonsListError } =
    useGetListQuery(
      {
        keys: ['dischargeReasons'],
      },
      {
        selectFromResult: ({ data, error }) => ({
          dischargeReasonList: data?.getList[0]?.list,
          error,
        }),
      },
    )

  useEffect(() => {
    if (dischargeReasonsListError) {
      notify({
        severity: 'warning',
        message:
          dischargeReasonsListError?.message ||
          'There was an error retrieving discharge reason options.',
      })
    }
  }, [dischargeReasonsListError, notify])

  const { data: careTeamOptions, error: ctError } = useCareTeamAggregates()

  useEffect(() => {
    if (ctError) {
      notify({
        severity: 'warning',
        message: 'There was an error getting Care Team Member names.',
      })
    }
  }, [ctError, notify])

  useEffect(() => {
    if (insurancePlansError) {
      notify({
        severity: 'warning',
        message: 'Failed to load insurance data.',
      })
    }
  }, [notify, insurancePlansError])

  useEffect(() => {
    if (aggsError) {
      notify({
        severity: 'warning',
        message: 'Failed to load patient aggregations',
      })
    }
  }, [notify, aggsError])

  // useEffect(() => {
  //   if (tracksError) {
  //     notify({
  //       severity: 'warning',
  //       message: 'Failed to load patient track options',
  //     })
  //   }
  // }, [notify, tracksError])

  const { insurancePlansMap, insurancePlansValueOptions } = useMemo(() => {
    return (_insurancePlans || []).reduce(
      (sum, val) => {
        const { _id, name, stateCode } = val
        sum.insurancePlansMap[_id] = val
        sum.insurancePlansValueOptions.push({
          value: _id,
          label: `${stateCode} - ${name}`,
        })
        return sum
      },
      {
        insurancePlansMap: {},
        insurancePlansValueOptions: [],
      } as {
        insurancePlansMap: Record<string, AllInsurancePlansMinimalFragment>
        insurancePlansValueOptions: { value: string; label: string }[]
      },
    )
  }, [_insurancePlans])

  const tagList = useMemo(() => {
    return (
      tags?.buckets
        .map((b) => b.key)
        .filter((i) => i !== '')
        .sort((a, b) => a.localeCompare(b)) || []
    )
  }, [tags])

  const getDetailPanelContent = useCallback<
    NonNullable<
      PersistentDataGridProps<PatientSearchGridRowModel>['getDetailPanelContent']
    >
  >(
    ({ row }) => {
      const id = row._patient.treatmentId
      if (idsWithTreatmentNotificationMap?.has(row._patient.treatmentId)) {
        return (
          <MessagesDetailPanel
            treatmentId={id}
            idsWithTreatmentNotificationMap={idsWithTreatmentNotificationMap}
          />
        )
      }
      return null
    },
    [idsWithTreatmentNotificationMap],
  )
  const columns: ServerSideColumn<PatientSearchGridRowModel>[] = useMemo(
    () => [
      {
        field: ColumnKey.name,
        headerName: 'Patient Name',
        osFieldKey: 'name.fullName',
        flex: 2,
        minWidth: 100,
        valueFormatter: (
          params: GridValueFormatterParams<
            PatientSearchGridRowModel[ColumnKey.name]
          >,
        ) => params.value?.fullName || '',
        renderCell: (
          params: GridRenderCellParams<PatientSearchGridRowModel, string>,
        ) => (
          <HoverFullContentCell
            width={params.colDef.computedWidth}
            value={
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                onClick={(e) => e.stopPropagation()}
                gap={1}
              >
                <Link
                  underline="none"
                  target="_blank"
                  sx={{
                    color: (theme) => theme.palette.secondary.dark,
                  }}
                  to={formatPatientLink(params.row._patient.treatmentId)}
                >
                  {params.formattedValue}
                </Link>
                {params.row._patient.nextgenId && (
                  <NextGenIcon
                    alt="NextGen"
                    title={`Patient Nextgen ID: ${params.row._patient.nextgenId}`}
                  />
                )}
                {params.row._patient.emrId && (
                  <DrChronoIcon
                    emrId={params.row._patient.emrId}
                    isActive={params.row._patient.isIntegrationActive || false}
                  />
                )}
              </Box>
            }
          />
        ),
      },
      {
        field: ColumnKey.mrn,
        headerName: 'MRN',
        flex: 1,
        minWidth: 80,
        renderCell: (
          params: GridRenderCellParams<PatientSearchGridRowModel, string>,
        ) => (
          <HoverFullContentCell
            width={params.colDef.computedWidth}
            value={params.formattedValue}
          />
        ),
      },
      {
        field: ColumnKey.status,
        headerName: 'Status',
        flex: 1,
        minWidth: 70,
        type: 'singleSelect',
        valueFormatter: ({ value }: GridValueFormatterParams<string>) =>
          t(`PatientStatus.${value}`),
        valueOptions: Object.values(PatientStatus).map((v) => ({
          value: v,
          label: t(`PatientStatus.${v}`),
        })),
      },
      {
        type: 'singleSelect',
        field: ColumnKey.dischargeReason,
        headerName: 'Discharge Reason',
        flex: 1,
        winWidth: 90,
        valueOptions: dischargeReasonList?.map((r) => r.label) || [],
      },
      ...(isWellness
        ? [
            {
              type: 'singleSelect',
              field: ColumnKey.warnings,
              osFieldKey: 'notices.code',
              headerName: 'Warnings',
              flex: 2,
              minWidth: 100,
              valueOptions: WarningFilterOptions.map((option) => {
                return {
                  value: option,
                  label: t(`noticeCode.${option}`),
                }
              }),
              sortable: false,
              filterItemToOSParam: (item: GridFilterItem, field: string) => {
                if (
                  item.operator === OperatorValueSingleSelect.isEmpty ||
                  item.operator === OperatorValueSingleSelect.isNotEmpty
                ) {
                  const query: QueryDslQueryContainer = {
                    bool: {
                      [item.operator === OperatorValueSingleSelect.isEmpty
                        ? 'must_not'
                        : 'must']: {
                        nested: {
                          path: 'notices',
                          query: {
                            bool: {
                              must: {
                                exists: {
                                  field: 'notices.code',
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  }
                  return query
                }

                const query = handleSelectOsFilter(item, field)
                if (!query) return query
                if (item.operator === OperatorValueSingleSelect.not) {
                  const query: QueryDslQueryContainer = {
                    bool: {
                      must_not: {
                        nested: {
                          path: 'notices',
                          query: {
                            bool: {
                              must: {
                                term: {
                                  'notices.code.keyword': item.value?.value,
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  }
                  return query
                }

                return {
                  nested: {
                    path: 'notices',
                    query,
                  },
                }
              },
              renderCell: (
                params: GridRenderCellParams<
                  PatientSearchGridRowModel,
                  PatientSearchGridRowModel[ColumnKey.warnings]
                >,
              ) => {
                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    maxWidth="100%"
                    overflow="hidden"
                  >
                    {params.value?.map((w) => (
                      <WarningChip notice={w} key={w.label} />
                    ))}
                  </Box>
                )
              },
            },
          ]
        : []),
      {
        type: 'date',
        field: ColumnKey.dateOfBirth,
        headerName: 'Date of Birth',
        flex: 1,
        minWidth: 100,
        valueFormatter: ({
          value,
        }: GridValueFormatterParams<string | null | undefined>) =>
          formatIsoDate(value, 'PP'),
        renderCell: renderHoverFullContentCell,
      },
      {
        field: ColumnKey.primaryPhone,
        headerName: 'Primary Phone',
        flex: 1.2,
        minWidth: 100,
        sortable: false,
        renderCell: renderHoverFullContentCell,
      },
      {
        field: ColumnKey.primaryEmail,
        headerName: 'Primary Email',
        flex: 1.2,
        minWidth: 100,
        renderCell: renderHoverFullContentCell,
      },
      {
        field: ColumnKey.timeZone,
        type: 'singleSelect',
        headerName: 'Patient Time Zone',
        valueOptions: getTimezoneValues(),
        flex: 1,
        minWidth: 80,
        renderCell: renderHoverFullContentCell,
      },
      ...(isWellness
        ? [
            {
              type: 'singleSelect',
              field: ColumnKey.insurance,
              headerName: 'Insurance Plans',
              osFieldKey: 'billingInsurances.insurancePlanId',
              flex: 2,
              minWidth: 100,
              sortable: false,
              valueOptions: insurancePlansValueOptions,
              renderCell: ({
                value,
              }: GridRenderCellParams<
                PatientSearchGridRowModel,
                PatientSearchGridRowModel[ColumnKey.insurance]
              >) => (
                <Stack gap={0.5}>
                  {!!insurancePlansValueOptions.length &&
                    value?.map(
                      ({ type, insurancePlanId, outOfNetworkPlan }) => {
                        const { name, stateCode, isInNetwork } =
                          (insurancePlanId
                            ? insurancePlansMap[insurancePlanId]
                            : {
                                name: outOfNetworkPlan?.name,
                                stateCode: outOfNetworkPlan?.state?.code,
                                isInNetwork: false,
                              }) || {}

                        return (
                          <Stack
                            key={type}
                            direction="row"
                            alignItems="center"
                            gap={0.5}
                          >
                            {type === InsuranceOrdinality.Primary && (
                              <Tooltip title="Primary Insurance">
                                <Star
                                  color="secondary"
                                  sx={{ fontSize: '1.375em' }}
                                />
                              </Tooltip>
                            )}
                            {!isInNetwork && (
                              <Tooltip title="Out Of Network">
                                <NotInterested sx={{ fontSize: '1.25em' }} />
                              </Tooltip>
                            )}
                            {type !== InsuranceOrdinality.Primary &&
                              isInNetwork && <Box width="1.125rem" />}
                            <Typography
                              color={isInNetwork ? 'inherit' : 'secondary'}
                            >
                              {`${stateCode} - ${name}`}
                            </Typography>
                          </Stack>
                        )
                      },
                    )}
                </Stack>
              ),
            },
          ]
        : []),
      {
        field: ColumnKey.nextAppointment,
        headerName: 'Next Appointment',
        osFieldKey: 'upcomingAppointments.startDateTime',
        flex: 2,
        minWidth: 100,
        renderCell: renderHoverFullContentCell,
        filterOperators: nextAppointmentFilterOperators,
        sortItemToOSParam: (
          { sort }: GridSortItem,
          field: string,
        ): SearchSortContainer | undefined => {
          if (!sort) return undefined
          return {
            [field]: {
              order: sort,
              mode: 'min',
              nested: {
                path: 'upcomingAppointments',
                filter: {
                  bool: {
                    must: [
                      {
                        exists: {
                          field,
                        },
                      },
                      {
                        range: {
                          'upcomingAppointments.endDateTime': {
                            gte: new Date().toISOString(),
                          },
                        },
                      },
                    ],
                  },
                },
              },
            },
          }
        },
        filterItemToOSParam: handleUpcomingAppointmentsFilter,
        valueGetter: (
          params: GridValueGetterParams<
            PatientSearchGridRowModel,
            PatientSearchGridRowModel[ColumnKey.nextAppointment]
          >,
        ) => {
          if (params.value) {
            return formatAppt(params.value)
          }
          return ''
        },
      },
      {
        field: ColumnKey.upcomingAppointments,
        headerName: 'Upcoming Appointments',
        osFieldKey: 'upcomingAppointments.startDateTime',
        flex: 2,
        minWidth: 100,
        sortable: false,
        valueGetter: (
          params: GridValueGetterParams<
            PatientSearchGridRowModel,
            PatientSearchGridRowModel[ColumnKey.upcomingAppointments]
          >,
        ) => {
          if (params.value) {
            return params.value.map((v) => formatAppt(v))
          }
          return []
        },
        filterItemToOSParam: handleUpcomingAppointmentsFilter,
        renderCell: (
          params: GridRenderCellParams<PatientSearchGridRowModel, string[]>,
        ) => (
          <ExpandCollapseCell
            values={params.value || []}
            sx={{ textWrap: 'nowrap', overflow: 'hidden' }}
          />
        ),
      },
      {
        type: 'singleSelect',
        field: ColumnKey.genderIdentity,
        headerName: 'Gender Identity',
        flex: 1,
        minWidth: 100,
        valueOptions: genderIdentityArray.map((type) => {
          return {
            value: type,
            label: t(`PatientGenderIdentity.${type}`),
          }
        }),
        valueFormatter: ({
          value,
        }: GridValueFormatterParams<string | null | undefined>) =>
          value && t(`PatientGenderIdentity.${value}`),
      },
      {
        type: 'singleSelect',
        field: ColumnKey.careTeam,
        osFieldKey: 'careTeam.careManagerId',
        headerName: 'PCTM',
        filterItemToOSParam: handleCareTeamFilter(true),
        flex: 1,
        minWidth: 100,
        sortable: false,
        valueOptions: careTeamOptions || [],
        valueFormatter: (
          v: GridValueFormatterParams<{ display?: string } | undefined | null>,
        ) => v.value?.display || '',
        renderCell: renderHoverFullContentCell,
      },
      {
        type: 'singleSelect',
        field: ColumnKey.npctm,
        osFieldKey: 'careTeam.careManagerId',
        headerName: 'Non-PCTMs',
        filterItemToOSParam: handleCareTeamFilter(false),
        flex: 1,
        minWidth: 100,
        sortable: false,
        valueOptions: careTeamOptions || [],
        valueGetter: (
          params: GridValueGetterParams<
            PatientSearchGridRowModel,
            PatientSearchGridRowModel[ColumnKey.npctm]
          >,
        ) => {
          return params.value?.map((v) => v.display || '')
        },
        renderCell: renderHoverFullContentCellNewLine,
      },
      {
        type: 'singleSelect',
        field: ColumnKey.tags,
        headerName: 'Tags',
        flex: 1,
        minWidth: 100,
        sortable: false,
        valueOptions: tagList,
        valueFormatter: ({ value }: GridValueFormatterParams<string[]>) =>
          value.join(', '),
      },
      {
        field: ColumnKey.messages,
        headerName: 'Messages',
        sortable: false,
        filterable: true,
        disableExport: true,
        filterOperators: getOsGridSingleSelectOperators().filter(
          (o) =>
            o.value === OperatorValueStr.isEmpty ||
            o.value === OperatorValueStr.isNotEmpty,
        ),
        filterItemToOSParam: (
          item: GridFilterItem,
        ): QueryDslQueryContainer | undefined => {
          if (item.operator === OperatorValueStr.isNotEmpty)
            return {
              bool: {
                must: [
                  {
                    terms: {
                      treatmentId: idsWithTreatmentNotificationArray || [],
                    },
                  },
                ],
              },
            }
          if (item.operator === OperatorValueStr.isEmpty)
            return {
              bool: {
                must_not: [
                  {
                    terms: {
                      treatmentId: idsWithTreatmentNotificationArray || [],
                    },
                  },
                ],
              },
            }
        },
      },
      {
        field: ColumnKey.globalTracks,
        headerName: 'Global Tracks',
        flex: 1,
        minWidth: 100,
        sortable: false,
        type: 'singleSelect',
        osFieldKey: 'tracks.name',
        filterOperators: getOsGridSingleSelectOperators().filter((f) =>
          (
            [
              OperatorValueSingleSelect.isAnyOf,
              OperatorValueSingleSelect.isEmpty,
              OperatorValueSingleSelect.isNotEmpty,
            ] as string[]
          ).includes(f.value),
        ),
        filterItemToOSParam: filterItemToOSParamForTracks('population'),
        valueOptions: globalTracks,
        valueFormatter: ({ value }: GridValueFormatterParams<Track[]>) =>
          value?.map((v) => v.name).join(', ') || '',
        renderCell: (params) => {
          const tracks = params.row.globalTracks || []
          return (
            <HoverFullContentCell
              value={
                tracks?.map((val) => {
                  return <div key={val.planId}>{val.name}&nbsp;</div>
                }) || ''
              }
              width={params.colDef.computedWidth}
            />
          )
        },
      },
      {
        field: ColumnKey.individualTracks,
        headerName: 'Individual Tracks',
        flex: 1,
        minWidth: 100,
        sortable: false,
        type: 'singleSelect',
        osFieldKey: 'tracks.name',
        filterOperators: getOsGridSingleSelectOperators().filter((f) =>
          (
            [
              OperatorValueSingleSelect.isAnyOf,
              OperatorValueSingleSelect.isEmpty,
              OperatorValueSingleSelect.isNotEmpty,
            ] as string[]
          ).includes(f.value),
        ),
        filterItemToOSParam: filterItemToOSParamForTracks('individual_public'),
        valueOptions: individualPublicTracks,
        valueFormatter: ({ value }: GridValueFormatterParams<Track[]>) =>
          value?.map((v) => v.name).join(', ') || '',
        renderCell: (params) => {
          const tracks = params.row.individualTracks || []
          return (
            <HoverFullContentCell
              value={
                tracks?.map((val) => {
                  return <div key={val.planId}>{val.name}&nbsp;</div>
                }) || ''
              }
              width={params.colDef.computedWidth}
            />
          )
        },
      },
      {
        field: ColumnKey.actions,
        headerName: 'Actions',
        minWidth: 130,
        type: 'actions',
        disableColumnMenu: true,
        renderCell: (
          params: GridRenderCellParams<PatientSearchGridRowModel>,
        ) => {
          const {
            id,
            treatmentId,
            status,
            name: { firstName, lastName },
            videoRoomId,
            mrn,
            emrId,
            lumaId,
            nextgenId,
            isIntegrationActive,
            primaryEmail,
            primaryPhone,
          } = params.row._patient

          return (
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {!hideStartSessionButton && (
                <StartPatientVideoSession
                  enablePatientStatusCheck={!!isWellness}
                  patientId={id}
                  treatmentId={treatmentId}
                  patientStatus={status}
                  enabledProps={{
                    title: 'Start Video Call',
                  }}
                >
                  <IconButton sx={{ mr: 1 }}>
                    <VideoCall />
                  </IconButton>
                </StartPatientVideoSession>
              )}
              <TreatmentActionsMenu
                {...{
                  treatmentId,
                  firstName,
                  lastName,
                  mrn,
                  emrId,
                  lumaId,
                  nextgenId,
                  videoLink: `
                  https://${process.env.TELEHEALTH_UI_DOMAIN}/telehealth/login?rid=${videoRoomId}
                  `,
                  phone: primaryPhone ?? '',
                  email: primaryEmail ?? '',
                  isIntegrationActive,
                  status,
                }}
                disableExport
              />
            </Box>
          )
        },
      },
      // hidden fields
      {
        field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
        sortable: false,
        filterable: false,
        disableExport: true,
      },
    ],
    [
      dischargeReasonList,
      isWellness,
      insurancePlansValueOptions,
      careTeamOptions,
      tagList,
      globalTracks,
      individualPublicTracks,
      formatPatientLink,
      t,
      insurancePlansMap,
      idsWithTreatmentNotificationArray,
      hideStartSessionButton,
      TreatmentActionsMenu,
    ],
  )

  const { gridProps, queryProps } = useServerSideGrid(
    columns,
    initialState,
    quickFilterFields,
    defaultQuickFilterSortOverride,
  )

  const args = useMemo(() => {
    return {
      ...queryProps,
      sort: queryProps.sort.concat({ 'treatmentId.keyword': 'asc' }),
    }
  }, [queryProps])

  const { searchablePatients, resultCount, isFetching, error } =
    useSearchPatientsQuery(args, {
      selectFromResult: ({ data, isFetching, error }) => ({
        searchablePatients: data?.searchPatients.patients || emptyRes,
        resultCount: data?.searchPatients.resultCount || 0,
        isFetching,
        error,
      }),
    })

  useEffect(() => {
    if (error) {
      console.error(error.message)
      notify({
        severity: 'error',
        message:
          error.message ||
          'An error occured while retrieving patients from the server.',
      })
    }
  }, [error, notify])

  const rows: PatientSearchGridRowModel[] = useMemo(() => {
    return searchablePatients.map((p) =>
      patientToRow(
        p,
        idsWithTreatmentNotificationMap ||
          new Map<string, CMChannelItemOverview[]>(),
      ),
    )
  }, [searchablePatients, idsWithTreatmentNotificationMap])

  return (
    <>
      <Box sx={{ p: 2, height: '100%' }}>
        <PersistentDataGrid
          {...gridProps}
          name={GRID_NAME}
          defaultGridState={initialState}
          density="compact"
          loading={
            isFetching ||
            (isInsuranceLoading &&
              initialState?.columns?.columnVisibilityModel?.[
                ColumnKey.insurance
              ])
          }
          rows={rows}
          rowCount={resultCount}
          getRowHeight={() => 'auto'}
          disableRowSelectionOnClick
          slotProps={{
            columnsPanel: {
              getTogglableColumns,
            },
            toolbar: {
              disableFeatures: ['exportButton'],
              children: canAddPatient && (
                <RegisterTreatmentButton formatSuccessRoute={formatPatientLink}>
                  <Button startIcon={<Add />} sx={{ order: 1000 }}>
                    {t('addNewPatient')}
                  </Button>
                </RegisterTreatmentButton>
              ),
            },
          }}
          columnVisibilityModel={{
            ...columnVisibilityModel,
            [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
          }}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        />
      </Box>
      <TreatmentActionsMenuPopups />
    </>
  )
}
