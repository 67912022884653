import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import throttle from 'lodash/throttle'
import {
  patientSearchApi,
  type MinimalSearchablePatientFrag,
  PatientProfileOverview,
  PatientStatus,
} from '@valerahealth/rtk-query'
import { TFunction } from '@valerahealth/ui-translation'
import { Box, ListItemText, MenuItem, Tooltip, Typography } from '@mui/material'
import { useTranslation } from '../../utils/hooks/useTranslation'
import { Combobox, type ComboboxProps } from '../../form'
import { formatIsoDate } from '../../utils/date'
import { NextGenIcon } from '../Icons'

export function patientDisplayName({
  firstName,
  lastName,
  preferredName,
}: PatientProfileOverview) {
  const m = preferredName ? `(${preferredName}) ` : ''
  return `${firstName || ''} ${m}${lastName || ''}`
}

const defaultVal: MinimalSearchablePatientFrag[] = []

function OptionBox({
  o,
  t,
}: {
  o: MinimalSearchablePatientFrag
  t: TFunction
}) {
  return (
    <Box display="flex" flexDirection="column">
      <Typography
        sx={{
          color: (theme) => theme.palette.secondary.dark,
        }}
      >
        {o.name.fullName} {o.nextgenId && <NextGenIcon />}
      </Typography>
      <Typography
        sx={{
          fontSize: (theme) => theme.typography.caption,
          color: (theme) => theme.palette.secondary.dark,
        }}
      >{`${`${o?.dateOfBirth ? formatIsoDate(o.dateOfBirth, 'P') : ''}`}${
        o.mrn ? `, ${o.mrn}` : ''
      }${`, ${t(`PatientStatus.${o.status}`)}`}`}</Typography>
    </Box>
  )
}

export function PatientTypeAhead(
  props: Omit<
    ComboboxProps<MinimalSearchablePatientFrag, false>,
    'options' | 'loading'
  > & {
    getLink?: (o: MinimalSearchablePatientFrag) => string
    patientStatusBlackList?: PatientStatus[]
  },
) {
  const { t } = useTranslation()
  const { patientStatusBlackList } = props
  const [searchPatients, { data, isFetching, isError }] =
    patientSearchApi.useLazyPatientTypeAheadQuery({
      selectFromResult: ({ data, isFetching, isError }) => ({
        isFetching,
        isError,
        data,
      }),
    })

  const options = useMemo(() => {
    return data?.patients || defaultVal
  }, [data])
  const debounceSearchPatients = React.useMemo(
    () => throttle(searchPatients, 1000, { trailing: true }),
    [searchPatients],
  )

  const { helperText, isOptionEqualToValue, getLink, ...rest } = props

  return (
    <Combobox
      getOptionLabel={(o) => {
        return o.name.fullName
      }}
      renderOption={(spread, o, state) => {
        const isLast = state.index === options.length - 1
        const hasMore =
          data && isLast ? data.resultCount - data.patients.length : 0

        const menuItem = (
          <MenuItem {...spread} key={o.treatmentId} id={o.treatmentId}>
            <Tooltip
              sx={{ whiteSpace: 'pre-wrap' }}
              placement="right"
              title={
                <pre>{`Email: ${o?.primaryEmail}\nPhone: ${o?.primaryPhone}`}</pre>
              }
            >
              <div>
                {getLink ? (
                  <Link to={getLink(o)}>
                    <OptionBox o={o} t={t} />
                  </Link>
                ) : (
                  <OptionBox o={o} t={t} />
                )}
              </div>
            </Tooltip>
          </MenuItem>
        )
        const hasMoreText = hasMore ? (
          <MenuItem
            key="hasMoreText"
            sx={{
              pointerEvents: 'none',
              borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <ListItemText
              primary={t('PatientTypeAhead.hasMorePrimaryText', {
                count: hasMore,
              })}
              secondary={t('PatientTypeAhead.hasMoreSecondaryText')}
            />
          </MenuItem>
        ) : null
        return [menuItem, hasMoreText]
      }}
      isOptionEqualToValue={
        isOptionEqualToValue || ((o, v) => o.treatmentId === v.treatmentId)
      }
      filterOptions={(os) => os} // this overrides the defualt createFilterOptions().
      {...rest}
      options={options}
      getOptionKey={(o) => o.treatmentId}
      helperText={isError ? t('PatientTypeAhead.optionsLoadFail') : helperText}
      loading={isFetching}
      textFieldProps={{
        placeholder: t('PatientTypeAhead.placeholder'),
        ...props?.textFieldProps,
        onChange: (e) => {
          if (e.target.value) {
            debounceSearchPatients({
              queryText: e.target.value,
              pageSize: 6,
              patientStatusBlackList,
            })
          }
        },
      }}
    />
  )
}

export * from './usePatientVerification'
