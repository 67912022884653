import { createService } from '../../utils'
import { getBaseApi } from './baseApi'
import enhanceEndpoints from './enhanceApi'

const appointmentIntegrationsGQLService = createService(
  ({ origin, getAccessToken }) => {
    const api = getBaseApi(origin, getAccessToken)
    return enhanceEndpoints(api)
  },
)

export const appointmentIntegrationsApi = appointmentIntegrationsGQLService({
  origin: `https://${process.env.APPOINTMENT_INTEGRATIONS_API_DOMAIN}`,
})

export type AppointmentIntegrationsApiType = typeof appointmentIntegrationsApi
