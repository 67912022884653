import { ReoccuranceType, SessionType } from '@valerahealth/rtk-query'

export default {
  SchedulingSidebar: {
    addEditScheduleTitle: '{{mode}} Schedule',
    scheduledListTitle: '{{serviceCategory}} Schedules',
    appointmentListFutureTitle: 'Future {{code}} Appointments',
    appointmentListPastTitle: 'Past {{code}} Appointments',
    allPastAppointmentsLoaded:
      'All past {{code}} appointments have been loaded',
    allFutureAppointmentsLoaded:
      'All future {{code}} appointments have been loaded',
    noFutureAppointments: 'No future {{code}} appointments are scheduled.',
    returnToFutureAppointments: 'Return to {{code}} appointments',
    addAppointment: 'Add {{code}} appointment',
    addNamedSchedule: 'Add {{serviceCategory}}',
    confirmDeleteHeader: 'Delete {{serviceCategory}}',
    confirmDeleteBody:
      'Are you sure you want to delete the {{serviceCategory}} schedule?',
    confirmDeleteApptBody: 'Are you sure you want to delete this appointment?',
  },
  AppointmentSideBar: {
    addEditAppointmentTitle: '{{mode}} {{serviceType}}',
  },
  AddEditSchedule: {
    dayTimesRequired: 'A time window is required for one or more days.',
    dayTimesOverlappingDate:
      '{{dayOfWeek}} from {{startTime}} to {{endTime}} is overlapping with another time window.',
    dayTimeAddHelper: 'Add another time period to this day',
    dayTimeCopyHelper: 'Copy this days time periods to all available days',
    dayTimeRemoveHelper: '$t(remove) time period',
    notLicensedAtLocationWarningHeader: 'Schedule Warning',
    notLicensedAtLocationWarningBody:
      'Are you sure you want to schedule this provider at {{locationNames}}? This provider is not licensed to work in the state(s) of {{locationStates}}. Current licenses for this provider can be reviewed <0>here</0>.',
  },
  AddEditOOOAppt: {
    coveringProvider: 'Covering Provider',
    coveringProviderDescription:
      'Select a Covering Provider to answer alerts and messages from your patients while you are Out of Office.',
    providerSelectTooltip:
      'Add Start and End Dates before selecting a Covering Provider',
    conflictOOO: 'This provider has overlapping out of office dates.',
    noConflictOOO: 'The selected provider is available!',
  },
  serviceCategory: 'Schedule Type',
  appointmentType: 'Appointment Type',
  addSchedule: 'Add Schedule',
  api: {
    appointment: {
      saveSuccess: 'Appointment was successfully saved.',
      saveFailure: 'Could not save appointment.',
      deleteSuccess: 'Appointment was successfully deleted.',
      deleteFailure: 'Could not delete appointment.',
      waitForApptLoading:
        'Loading appointment data, please wait a few seconds and retry...',
      conflictOOOSaveError:
        'Please choose a covering provider that is not out of office at the same time.',
      OOODateTimeSaveError: 'Please choose valid date and time.',
      overlappingOOOSaveError:
        'Please choose different date/time, you can not create overlapping OOOs.',
      overlappingApptSaveError:
        'Please choose different date/time, you can not create overlapping appointments.',
      saveFailureOverlapPractitioner:
        'Scheduled patient appointments (non-canceled appointments) cannot overlap with another existing patient appointment. Please reschedule or cancel the existing appointment to schedule a new patient appointment at the same time.',
      saveFailureOverlapPatient:
        'Scheduled patient appointments (non-canceled appointments) cannot overlap with another existing patient appointment. This patient already booked another appointment in this time slot, please select a different time.',
      saveFailureOverlapBoth:
        'Scheduled patient appointments (non-canceled appointments) overlaps with more than one existing patient appointments. This patient already booked another appointment in this time slot, please select a different time.',
    },
    schedule: {
      saveSuccess: 'Schedule was successfully saved.',
      saveFailure: 'Could not save schedule.',
      deleteSuccess: 'Schedule was successfully deleted.',
      deleteFailure: 'Could not delete schedule.',
    },
  },
  viewHistory: 'View history',
  SessionType: {
    [SessionType.ValeraVideo]: 'Valera Video',
    [SessionType.VirtualVisit]: 'Virtual Visit',
    [SessionType.GoogleMeet]: 'Google Meet',
    [SessionType.InPerson]: 'In Person',
    [SessionType.Other]: 'Other',
  },
  consentPacketNotSigned: 'Consent Packet Not Signed',
  missingNextGenIdPatient: 'Patient is not linked to NextGen and the appointment will not sync to NextGen. Please contact support for assistance.',
  missingNextGenIdProvider: 'Provider is not linked to NextGen and the appointment will not sync to NextGen. Please contact support for assistance.',
  patientIn: `Patient in`,
  duration: 'Duration',
  date: 'Date',
  url: 'URL',
  copyURL: 'Copy URL',
  sessionType: 'Session Type',
  startSession: 'Start Session',
  provider: 'Provider',
  service: 'Service',
  disabled: 'Disabled',
  consentPacketWarning: 'Consent Packet Warning',
  consentPacketWarningMessage:
    'Are you sure you want to schedule this appointment? The patient has not yet signed the Consent Packet.',
  ReoccuranceType: {
    [ReoccuranceType.Weekly]: 'Weekly',
    [ReoccuranceType.Monthly]: 'Monthly',
  },
  apointmentConflict: {
    providerNotAvailable:
      'The recurring appointment could not be scheduled, only proposed, on {{startDate}} - {{startTime}} - {{endTime}} because the provider is not available.',
    patientNotAvailable:
      'The recurring appointment could not be scheduled on {{startDate}} - {{startTime}} - {{endTime}} because the patient is not available.',
  },
  billableApptWarning:
    'This patient already has a billable appointment scheduled. It is advised to keep billable appointments on separate days unless patients are high risk.',
  therapyApptWarning:
    'This patient already has a therapy appointment scheduled this week. It is advised to keep therapy appointments to once per week unless patients are high risk.',
  psychiatryApptWarning:
    'This patient already has a psychiatry appointment scheduled this week. It is advised to keep psychiatry appointments to once per week unless patients are high risk.',
  confirmScheduleApptHeader: 'Confirm Schedule Appointment',
  cancelConfirmation: 'Cancel Confirmation',
  cancelConfirmationDetail: 'Are you sure you want to cancel this appointment?',
  cancelationReason: 'Cancelation Reason',
  paymentType: 'Payment Type',
  coPay: 'Co-Pay',
  coinsurance: 'Coinsurance',
  deductible: 'Deductible',
  familyDeductible: 'Family Deductible',
  billingProvider: 'Billing Provider',
  cancelationDetail: 'Cancelation Reason Details',
  reason: 'Reason *',
  additionalDetails: 'Additional Details',
  forceOverWriteTitle: 'Appointment Conflict',
  inactive: 'Inactive',
  description: 'Description',
  nextGenSyncError: 'NextGen sync error: {{syncMessage}}',
  serviceTypeCodeWarning:
    '{{selectedServiceType}} does not fall under the provider’s {{allowedServiceTypes}} service type(s).',
  nextgenSlotBothAvailable: 'Provider and patient have no conflicts in NextGen.',
  nextgenSlotProviderAvailable: 'Provider has no conflicts in NextGen.',
  nextgenSlotProviderUnavailable: 'Provider has conflict in NextGen.',
  nextgenSlotPatientUnavailable: 'Patient has conflict in NextGen.',
  nextgenSlotBothUnavailable: 'Provider and Patient have a conflict in Nextgen'
}
