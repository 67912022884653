import { ReactNode, useEffect } from 'react'
import { Check, Clear } from '@valerahealth/ui-components/icons'
import {
  CircularProgress,
  NextGenIcon,
  Typography,
  useNotify,
} from '@valerahealth/ui-components'
import { appointmentIntegrationsApi } from '@valerahealth/rtk-query'
import { useTranslation } from '../../locales'

const { useCheckEmrAvailabilityQuery } = appointmentIntegrationsApi

type NextGenAvailabilityMsgProps = {
  // in ISO
  startDate: Date
  endDate: Date
  providerEmrId: string
  patientEmrId?: string
}

export const NextGenAvailabilityMsg = (props: NextGenAvailabilityMsgProps) => {
  const [t] = useTranslation()
  const notify = useNotify()

  const { startDate, endDate, ...args } = props

  const { providerIsAvailable, patientIsAvailable, isFetching, error } =

    useCheckEmrAvailabilityQuery(
      {
        content: {
          ...args,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
      },
      {
        selectFromResult: ({ data, isFetching, isUninitialized, error }) => ({
          ...data?.checkEmrAvailability,
          isFetching: isFetching || isUninitialized,
          error,
        }),
      },
    )

  useEffect(() => {
    if (error) {
      notify({
        severity: 'error',
        message: error.message || 'An error occured while checking nextgen availability',
      })
    }  
  }, [error, notify])

  let content:ReactNode

  switch(true){
    case !!error: 
      content = null
      break
    case isFetching:
      content = <CircularProgress size={30} />
      break
    // both available
    case patientIsAvailable && providerIsAvailable:
      content = (
        <>
          <Check color="success" />
          <NextGenIcon />
          {t('nextgenSlotBothAvailable')}
        </>
      )
      break 
    // only queried for provider, and is available
    case providerIsAvailable && patientIsAvailable === null:
      content = (
        <>
          <Check color="success" />
          <NextGenIcon />
          {t('nextgenSlotProviderAvailable')}
        </>
      )
      break
    // Both unavailable
    case !providerIsAvailable && patientIsAvailable === false:
      content = (
        <>
          <Clear color="error" />
          <NextGenIcon />
          {t('nextgenSlotBothUnavailable')}
        </>
      )
      break
    case !providerIsAvailable:
      content = (
        <>
          <Clear color="error" />
          <NextGenIcon />
          {t('nextgenSlotProviderUnavailable')}
        </>
      )
      break
    case patientIsAvailable === false:
      content = (
        <>
          <Clear color="error" />
          <NextGenIcon />
          {t('nextgenSlotPatientUnavailable')}
        </>
      )
      break
    default:
  }

  return (
    <Typography sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
      {content}
    </Typography>
  )
  
}
